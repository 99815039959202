.baike-list-con {
    display: flex;
    .news-list-con {
        flex: 1;
        overflow: hidden;
    }
    .news-list {
        li {
            .img {
                width: px2rem(250px);
                height: px2rem(166px);
            }
            .info {
                margin: 0 px2rem(30px);
            }
            .t {
                font-size: 20px;
                margin-bottom: px2rem(10px);
            }
            .p {
                margin-bottom: px2rem(20px);
            }
            a:after {
                display: none;
            }
            @media (max-width: 750px) {
                .t {
                    font-size: 16px;
                }
                .p {
                    height: 44px;
                }
            }
        }
    }
    @media (max-width: 900px) {
        display: block;
        .news-list-con {
            padding-bottom: px2rem(30px);
        }
    }
}
.baike-sidebar {
    width: 324px;
    margin-left: 18px;
    padding-top: px2rem(30px);
    @media (max-width: 900px) {
        width: 100%;
        margin-left: 0;
    }
}
.baike-sidebar-box {
    display: block;
    margin-bottom: 20px;
    background: #fff;
    box-sizing: border-box;
    width: 100%;
    padding: px2rem(30px);
    .top {
        display: flex;
        align-items: center;
        margin-bottom: px2rem(15px);
        i {
            color: $blue;
            font-size: 24px;
            margin-right: 5px;
        }
        .t {
            flex: 1;
            overflow: hidden;
            font-size: 20px;
            color: #333;
            @media (max-width: 750px) {
                font-size: 16px;
            }
        }
        .more {
            margin-left: 5px;
            font-size: 14px;
            color: #999;
            &:after {
                font-family: 'iconfont';
                content: '\e84f';
                font-size: 16px;
                margin-left: 2px;
            }
            &:hover {
                color: $blue;
            }
        }
    }
    .list {
        li {
            border-top: 1px dashed #d5d5d5;
            padding: 10px 0;
            a {
                display: flex;
                width: 100%;
                &:hover {
                    .title {
                        color: $blue;
                    }
                }
            }
            .num {
                font-size: 16px;
                color: $blue;
                width: 20px;
                line-height: 20px;
            }
            .dot {
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background: $blue;
                margin: 6px 5px 0 0;
            }
            .info {
                flex: 1;
                overflow: hidden;
            }
            .title {
                font-size: 13px;
                line-height: 20px;
                max-height: 40px;
                color: #7a7a7a;
                transition: color .2s;
                @include multiEllipsis();
                &.toe {
                    max-height: 20px;
                    @include multiEllipsis(1);
                }
            }
            .time {
                line-height: 20px;
                font-size: 13px;
                color: $blue;
            }
        }
    }
    .pro-list {
        li {
            border-top: 1px dashed #d5d5d5;
            a {
                display: flex;
                padding: px2rem(15px) 0;
                align-items: center;
                &:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .title {
                        color: $blue;
                    }
                }
            }
            .img {
                width: px2rem(60px);
                height: px2rem(60px);
                background-color: #f2f2f2;
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    transition: all .5s;
                }
            }
            .title {
                flex: 1;
                margin-left: 10px;
                font-size: 16px;
                color: #333;
                transition: all .2s;
                @include multiEllipsis();
            }
        }
    }
}
.baike-detail-con {
    padding-top: px2rem(40px);
    padding-bottom: px2rem(20px);
    display: flex;
    .left {
        width: 270px;
    }
    .center {
        flex: 1;
        margin: 0 18px;
        overflow: hidden;
    }
    .right {
        width: 324px;
    }
    @media (max-width: 1024px) {
        display: block;
        .left,
        .right {
            display: none;
        }
        .center {
            margin: 0;
            width: 100%;
        }
    }
}
.baike-detail-box {
    background-color: #fff;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: px2rem(40px) px2rem(25px);
    .article-box {
        width: 100%;
        .top {
            padding-bottom: px2rem(24px);
            border-bottom: 1px dashed #b2b2b2;
            text-align: center;
            .t {
                font-size: 30px;
                color: #333;
                margin-bottom: px2rem(14px);
            }
            .time {
                font-size: 14px;
                color: #666;
            }
        }
        .article {
            padding-top: px2rem(20px);
            line-height: 30px;
            color: #777;
            overflow: hidden;
            @include articleReset(14px, 30px);
        }
    }
    .article-trigger-btn {
        display: none;
        text-align: center;
        color: $blue;
        i {
            display: block;
            transform: rotate(90deg);
            font-size: 18px;
        }
        .text {
            font-size: 16px;
        }
        &.open {
            i {
                transform: rotate(-90deg);
            }
        }
    }
    @media (max-width: 750px) {
        .article-box {
            .top {
                .t {
                    font-size: 18px;
                }
            }
        }
        .article-trigger-btn {
            display: block;
        }
    }
}
.baike-recommend {
    margin-bottom: px2rem(70px);
    &-hd {
        text-align: center;
        margin-bottom: px2rem(30px);
        span {
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            font-size: 20px;
            color: #333;
            cursor: pointer;
            &.on {
                color: $blue;
            }
            &:after {
                position: absolute;
                right: 0;
                top: 50%;
                width: 1px;
                height: 10px;
                background: #bfbfbf;
                content: '';
                margin-top: -5px;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
    &-bd {
        .box {
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
    &-list {
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            box-sizing: border-box;
            border-left: 1px solid #dcdcdc;
            padding: 0 px2rem(30px) px2rem(20px);
            &:nth-child(3n+1) {
                border-left: none;
            }
            .title {
                position: relative;
                padding-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 5px;
                &:before {
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background: $blue;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    color: #333;
                    transition: color .2s;
                    &:hover {
                        color: $blue;
                    }
                }
            }
            .time {
                font-size: 16px;
                color: $blue;
                line-height: 20px;
                padding-left: 10px;
            }
        }
    }
    @media (max-width: 750px) {
        &-hd {
            span {
                font-size: 16px;
            }
        }
        &-list {
            li {
                width: 100%;
                border-left: none;
                border-bottom: 1px dashed #d5d5d5;
                padding-top: px2rem(20px);
                .title {
                    font-size: 14px;
                    margin-bottom: 0;
                }
                .time {
                    font-size: 12px;
                }
            }
        }
    }
}