.captcha {
    &-title {
        height: 42px;
        width: 100%;
        text-align: center;
        border-radius: 2px;
        background-color: #f3f3f3;
        color: #bbb;
        font-size: 14px;
        letter-spacing: .1px;
        line-height: 42px;
    }

    &-show {
        display: none;
        height: 42px;
        width: 100%;
        text-align: center;
        border-radius: 2px;
        background-color: #f3f3f3;
    }

    &-loading {
        margin: auto;
        width: 70px;
        height: 20px;
    }

    &-loading-dot {
        float: left;
        width: 8px;
        height: 8px;
        margin: 18px 4px;
        background: #ccc;
        border-radius: 50%;
        opacity: 0;
        box-shadow: 0 0 2px black;
        animation: loadingFade 1s infinite;

        &:nth-child(2) {
            animation-delay: .1s
        }

        &:nth-child(3) {
            animation-delay: .2s
        }

        &:nth-child(4) {
            animation-delay: .3s
        }
    }
}

@keyframes loadingFade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        opacity: 0;
    }
}