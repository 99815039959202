.hotline-con {
    background-image: url(../images/bg1.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    overflow: hidden;
    margin-top: px2rem(60px);
    .hotline {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: px2rem(110px);
        padding-bottom: px2rem(110px);
        .img {
            margin-right: px2rem(20px);
        }
        .info {
            color: #333;
            .t {
                font-size: 22px;
            }
            .p {
                font-size: 42px;
                font-family: '方正大黑简体';
            }
        }
        @media (max-width: 750px) {
            .info {
                .t {
                    font-size: 20px;
                }
                .p {
                    font-size: 26px;
                }
            }
            .img {
                img {
                    max-width: px2rem(80px);
                }
            }
        }
    }
}
.hotline-list {
    display: flex;
    width: 100%;
    padding-bottom: 50px;
    .item {
        flex: 1;
        display: flex;
        position: relative;
        &:after {
            position: absolute;
            z-index: 1;
            width: 466px;
            height: 102px;
            max-width: 100%;
            background-image: url(../images/shadow1.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%);
            content: '';
        }
        + .item {
            margin-left: 24px;
        }
        .wp {
            box-sizing: border-box;
            background-color: #fff;
            position: relative;
            z-index: 2;
            padding: px2rem(50px);
            text-align: center;
            width: 100%;
        }
        .img {
            position: absolute;
            width: px2rem(86px);
            height: px2rem(86px);
            overflow: hidden;
            left: 50%;
            top: px2rem(-56px);
            margin-left: px2rem(-43px);
            z-index: 2;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .t {
            text-align: center;
            font-size: 24px;
            color: #333;
            margin-bottom: 5px;
        }
        .p {
            font-size: 16px;
            line-height: 24px;
            color: #888;
        }
    }
    @media (max-width: 750px) {
        display: block;
        padding-bottom: 0;
        .item {
            margin: 0 0 50px !important;
            .t {
                font-size: 20px;
            }
        }
    }
}