.contact-con {
    background-color: #fff;
    box-sizing: border-box;
    padding: px2rem(20px);
    display: flex;
    margin-top: px2rem(40px);
    margin-bottom: px2rem(70px);
    box-shadow: 0 25px 15px -10px rgba(0, 0, 0, .1);
    align-items: center;
    .map {
        flex: 1;
        overflow: hidden;
        height: 410px;
    }
    .info {
        width: 490px;
        box-sizing: border-box;
        padding: 0 px2rem(50px);
        font-size: 16px;
        color: #777;
        line-height: 26px;
        .t {
            font-family: '方正大黑简体';
            color: #555;
            font-size: 33px;
            line-height: 40px;
            margin-bottom: px2rem(20px);
        }
        .li {
            display: flex;
            margin-bottom: 10px;
            img {
                width: 26px;
                height: 26px;
                margin-right: 5px;
            }
            span {
                flex: 1;
                overflow: hidden;
            }
        }
    }
    @media (max-width: 1024px) {
        display: block;
        .info {
            width: 100%;
            padding: px2rem(30px) 0;
            .t {
                font-size: 26px;
            }
        }
    }
}