.news-list-con {
    padding: px2rem(30px) 0 px2rem(90px);
}
.news-list {
    li {
        margin-bottom: px2rem(40px);
        a {
            display: block;
            position: relative;
            &:after {
                position: absolute;
                width: 100%;
                height: 131px;
                background-image: url(../images/shadow2.png);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                content: '';
                left: 0;
                bottom: -35px;
            }
            &:hover {
                .img img {
                    transform: scale(1.05);
                }
                .info {
                    &:before {
                        width: 100%;
                    }
                }
                .t {
                    color: $blue;
                }
            }
        }
        .wp {
            display: flex;
            box-sizing: border-box;
            padding: px2rem(20px);
            background-color: #fff;
            align-items: center;
            position: relative;
            z-index: 2;
        }
        .img {
            width: px2rem(334px);
            height: px2rem(222px);
            overflow: hidden;
            span {
                display: block;
                width: 100%;
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .5s;
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
            margin: 0 px2rem(44px);
            border-bottom: 1px solid #e4e4e4;
            box-sizing: border-box;
            position: relative;
            &:before {
                position: absolute;
                width: 40px;
                height: 3px;
                left: 0;
                bottom: 0;
                background: $blue;
                content: '';
                display: block;
                transition: all .5s;
            }
        }
        .t {
            font-size: 22px;
            color: #333;
            transition: all .2s;
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: px2rem(20px);
        }
        .p {
            font-size: 14px;
            line-height: 28px;
            color: #777;
            height: 84px;
            overflow: hidden;
            margin-bottom: px2rem(30px);
        }
        .time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 102px;
            height: 102px;
            color: #fff;
            background: $blue;
            margin-right: px2rem(20px);
        }
        .day {
            font-size: 36px;
        }
        .date {
            font-size: 14px;
        }
        @media (max-width: 900px) {
            .info {
                margin-right: 0;
            }
            .time {
                display: none;
            }
        }
        @media (max-width: 750px) {
            .t {
                font-size: 16px;
            }
            .p {
                font-size: 12px;
                line-height: 22px;
                height: 66px;
            }
        }
    }
}
.news-detail-con {
    padding: px2rem(40px) 0 px2rem(80px);
}
.news-detail-box {
    background-color: #fff;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: px2rem(40px) px2rem(20px);
    .article-box {
        width: 1085px;
        max-width: 100%;
        margin: 0 auto;
        .top {
            padding-bottom: px2rem(24px);
            border-bottom: 1px dashed #b2b2b2;
            text-align: center;
            .t {
                font-size: 30px;
                color: #333;
                margin-bottom: px2rem(14px);
            }
            .time {
                font-size: 14px;
                color: #666;
            }
        }
        .article {
            padding-top: px2rem(20px);
            line-height: 30px;
            color: #777;
            @include articleReset(14px, 30px);
        }
    }
    @media (max-width: 750px) {
        .article-box {
            .top {
                .t {
                    font-size: 18px;
                }
            }
        }
    }
}