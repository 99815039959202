.partner-box {
    padding: px2rem(70px) 0 px2rem(50px);
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    background-size: cover;
    &.pb-0 {
        padding-bottom: 0;
    }
}
.anim-evt {
    transform: translateY(30px);
    opacity: 0;
    transition: all .4s;
    &.anim {
        transform: translateY(0);
        opacity: 1;
    }
}
.partner-title {
    text-align: center;
    color: #333;
    margin-bottom: px2rem(34px);
    .t {
        font-size: 36px;
        line-height: 60px;
    }
    .p {
        font-size: 16px;
    }
    &.color-white {
        color: #fff;
    }
    @media (max-width: 750px) {
        .t {
            font-size: 22px;
            line-height: 40px;
        }
        .p {
            font-size: 14px;
        }
    }
}
.partner-choose-list {
    margin-right: -22px;
    margin-left: -22px;
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        padding: 0 22px;
        margin-bottom: px2rem(45px);
    }
    .wrapper {
        position: relative;
        padding-bottom: 30px;
    }
    .img {
        background-color: #fff;
        width: 100%;
        height: 297px;
        position: relative;
        span {
            position: relative;
            display: block;
            width: 100%;
            height: 250px;
            overflow: hidden;
            backface-visibility: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
                display: block;
            }
        }
    }
    .t {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff;
        background: $blue;
        white-space: nowrap;
        font-size: 24px;
        height: 80px;
        line-height: 80px;
        bottom: 0;
        position: absolute;
        left: 28px;
        right: 28px;
        z-index: 2;
    }
    @media (max-width: 900px) {
        li {
            width: 50%;
        }
    }
    @media (max-width: 750px) {
        margin-right: -5px;
        margin-left: -5px;
        li {
            padding: 0 5px;
        }
        .img {
            height: 220px;
            span {
                height: 200px;
            }
        }
        .t {
            right: 15px;
            left: 15px;
            height: 50px;
            line-height: 50px;
            font-size: 14px;
        }
    }
}
.partner-sl-box {
    box-sizing: border-box;
    background-color: #fff;
    padding: px2rem(50px) px2rem(60px);
    display: flex;
    .big-img {
        width: 595px;
        img {
            display: block;
            width: 100%;
        }
    }
    .icon-con {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        .item {
            width: 33.33%;
            display: inline-flex;
            height: 50%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .icon {
            width: px2rem(81px);
            height: px2rem(81px);
            margin-bottom: 10px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .t {
            font-size: 20px;
            color: #000;
            line-height: 30px;
        }
        .p {
            font-size: 14px;
            line-height: 22px;
            color: #888;
        }
    }
    @media (max-width: 1024px) {
        display: block;
        .big-img {
            width: 100%;
            margin-bottom: px2rem(10px);
        }
        .icon-con {
            .item {
                padding: px2rem(10px) 0;
            }
        }
    }
    @media (max-width: 750px) {
        .icon-con {
            .p {
                font-size: 12px;
                text-align: center;
            }
        }
    }
}
.partner-join-con {
    display: flex;
    width: 100%;
    .big {
        flex: 735;
        overflow: hidden;
        margin-bottom: 10px;
        .img {
            position: relative;
            width: 100%;
            padding-top: (435/735) * 100%;
            overflow: hidden;
            display: block;
            @include imgCommon();
        }
    }
    .small {
        font-size: 0;
        flex: 625;
        overflow: hidden;
        .item {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            box-sizing: border-box;
            padding-left: 10px;
            padding-bottom: 10px;
        }
        .img {
            position: relative;
            width: 100%;
            padding-top: (211/300) * 100%;
            overflow: hidden;
            display: block;
            @include imgCommon();
        }
    }
    @media (max-width: 900px) {
        display: block;
        .small {
            .item {
                &:nth-child(2n+1) {
                    padding: 0 5px 10px 0 !important;
                }
                &:nth-child(2n) {
                    padding: 0 0 10px 5px !important;
                }
            }
        }
    }
}
.partner-intro-con {
    background-color: #fff;
    display: flex;
    margin-bottom: px2rem(90px);
    .item {
        flex: 1;
        overflow: hidden;
        height: 327px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:first-child {
            background-color: #f5f5f5;
        }
        .t {
            font-size: 22px;
            color: #333;
            margin-bottom: px2rem(40px);
        }
        .num-box {
            display: flex;
            width: 100%;
            justify-content: space-around;
        }
        .box {
            .num {
                color: $blue;
                strong,
                span {
                    font-family: '方正大黑简体';
                    font-size: 72px;
                    line-height: 80px;
                }
                sup {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 24px;
                }
            }
            .p {
                font-size: 14px;
                color: #333;
                text-align: center;
            }
        }
    }
    @media (max-width: 900px) {
        display: block;
    }
    @media (max-width: 750px) {
        .item {
            height: 270px;
            .box {
                .num {
                    strong,
                    span {
                        font-size: 50px;
                    }
                }
            }
        }
    }
}