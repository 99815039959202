.dealer-con {
    padding: px2rem(60px) 0 px2rem(80px);
    background: #eee;
    .wp1200 {
        display: flex;
        align-items: flex-start;
    }
    @media (max-width: 800px) {
        .wp1200 {
            display: block;
        }
    }
}
.dealer-menu {
    width: 260px;
    margin-right: 20px;
    background-color: #fff;
    border-radius: 6px;
    min-height: 400px;
    .title {
        padding-left: 40px;
        line-height: 80px;
        font-size: 22px;
        color: #555;
    }
    ul {
        li {
            border-top: 1px solid #f4f4f4;
            a {
                display: block;
                position: relative;
                padding-left: 40px;
                font-size: 14px;
                color: #333;
                transition: all .2s;
                height: 68px;
                line-height: 68px;
                overflow: hidden;
                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    background: $blue;
                    width: 2px;
                    height: 24px;
                    margin-top: -12px;
                    content: '';
                    display: none;
                }
            }
            &.on,
            &:hover {
                a {
                    color: $blue;
                    &:before {
                        display: block;
                    }
                }
            }
        }
    }
    @media (max-width: 800px) {
        width: 100%;
        margin-bottom: px2rem(20px);
        margin-right: 0;
        min-height: 0;
        .title {
            display: none;
        }
        ul {
            display: flex;
            li {
                flex: 1;
                a {
                    text-align: center;
                    padding: 0;
                    &:before {
                        left: 50%;
                        bottom: 0;
                        top: auto;
                        width: 50px;
                        margin: 0 0 0 -25px;
                        height: 2px;
                    }
                }
            }
        }
    }
    @media (max-width: 750px) {
        ul {
            li {
                a {
                    height: 50px;
                    line-height: 50px;
                    border-right: 1px solid #efefef;
                }
            }
        }
    }
}
.dealer-box {
    flex: 1;
    overflow: hidden;
    .paged {
        padding-top: px2rem(30px);
    }
}
.dealer-wrapper {
    border-radius: 5px;
    background-color: #fff;
}
.dealer-search {
    text-align: right;
    margin-bottom: px2rem(20px);
    width: 100%;
    form {
        display: inline-flex;
        vertical-align: top;
        width: 250px;
        @media (max-width: 750px) {
            width: 100%;
        }
    }
    .input {
        flex: 1;
        overflow: hidden;
        background-color: #fff;
        input {
            display: block;
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #333;
            box-sizing: border-box;
            padding: 0 10px;
            appearance: none;
            background: none;
            border: none;
        }
    }
    button {
        display: block;
        height: 40px;
        line-height: 40px;
        background: $blue;
        cursor: pointer;
        appearance: none;
        border: none;
        color: #fff;
        font-size: 14px;
        width: 50px;
        transition: all .2s;
        &:hover {
            background: lighten($blue, 5%);
        }
    }
}
.dealer-top {
    padding: 0 px2rem(60px);
    border-bottom: 1px solid #f4f4f4;
    position: relative;
    .dealer-prev {
        position: absolute;
        left: 0;
        line-height: 66px;
        width: px2rem(60px);
        text-align: center;
        font-size: 16px;
        outline: none;
        cursor: pointer;
        color: #999;
        top: 0;
        transform: rotate(180deg);
        &:hover {
            color: $blue;
        }
    }
    .dealer-next {
        position: absolute;
        right: 0;
        line-height: 66px;
        width: px2rem(60px);
        text-align: center;
        font-size: 16px;
        outline: none;
        cursor: pointer;
        color: #999;
        top: 0;
        &:hover {
            color: $blue;
        }
    }
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        width: auto;
        position: relative;
        a {
            display: block;
            height: 66px;
            line-height: 66px;
            font-size: 14px;
            color: #333;
            &:hover {
                color: $blue;
            }
        }
        &:before {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            height: 2px;
            background: $blue;
            display: none;
            content: '';
        }
        &.on {
            a {
                color: $blue;
            }
            &:before {
                display: block;
            }
        }
    }
    @media (max-width: 750px) {
        .dealer-prev,
        .dealer-next {
            line-height: 44px;
        }
        .swiper-slide {
            a {
                height: 44px;
                line-height: 44px;
            }
        }
    }
}
.dealer-profile {
    padding: px2rem(40px) px2rem(60px);
    box-sizing: border-box;
    min-height: px2rem(500px);
}
.dealer-form {
    width: 395px;
    max-width: 100%;
    display: block;
    .row {
        display: flex;
        margin-bottom: px2rem(20px);
    }
    .word {
        width: 75px;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        color: #454545;
    }
    .input {
        flex: 1;
        overflow: hidden;
        input {
            display: block;
            width: 100%;
            height: 48px;
            line-height: 48px;
            background: #f2f2f2;
            border: none;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 0 px2rem(20px);
            font-size: 14px;
            color: #454545;
            appearance: none;
        }
        button {
            display: block;
            height: 48px;
            line-height: 48px;
            border-radius: 24px;
            text-align: center;
            color: #fff;
            background: $blue;
            appearance: none;
            transition: all .2s;
            cursor: pointer;
            width: 160px;
            font-size: 15px;
            border: none;
            &:hover {
                background: lighten($blue, 5%);
            }
        }
    }
}
.dealer-notice-list {
    padding: px2rem(30px) px2rem(44px) px2rem(60px);
    li {
        border-bottom: 1px dashed #ddd;
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #fff;
        &:nth-child(2n) {
            background-color: #f9f9f9;
        }
        > i {
            display: block;
            width: 40px;
            text-align: center;
            font-size: 20px;
            color: $blue;
        }
        .time {
            font-size: 13px;
            color: #777;
            margin: 0 px2rem(20px);
            i {
                font-size: 16px;
                color: $blue;
            }
        }
        .title {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            a {
                font-size: 14px;
                color: #777;
                transition: all .2s;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}
.dealer-article {
    padding: px2rem(30px) px2rem(44px);
    &-title {
        text-align: center;
        font-size: 24px;
        color: #777;
        margin-bottom: 5px;
        @media (max-width: 750px) {
            font-size: 18px;
        }
    }
    &-time {
        text-align: center;
        font-size: 13px;
        color: #777;
        i {
            font-size: 18px;
            color: $blue;
        }
    }
    .article {
        font-size: 14px;
        line-height: 30px;
        color: #777;
        padding: px2rem(20px) 0;
        img {
            max-width: 100%;
            height: auto !important;
        }
    }
}
.dealer-download-list {
    .item {
        display: flex;
        border-bottom: 1px dashed #ddd;
        align-items: center;
        height: 50px;
        background-color: #fff;
        &:nth-child(2n+1) {
            background-color: #f9f9f9;
        }
        &:first-child {
            border-top: 1px dashed #ddd;
        }
        > i {
            display: block;
            width: 40px;
            text-align: center;
            font-size: 20px;
            color: $blue;
        }
        .t {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            a {
                font-size: 14px;
                color: #777;
                transition: all .2s;
                &:hover {
                    color: $blue;
                }
            }
        }
        .num {
            margin-left: px2rem(20px);
            font-size: 14px;
            color: #777;
            margin-right: px2rem(10px);
            i {
                font-size: 20px;
                color: $blue;
            }
            span {
                color: $blue;
            }
        }
    }
}