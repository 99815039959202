.feedback-con {
    margin-top: px2rem(80px);
    margin-bottom: px2rem(120px);
    background-image: url(../images/bg2.jpg);
    background-position: left center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    .ewm {
        width: 420px;
        text-align: center;
        font-size: 16px;
        color: #222;
        .img {
            width: px2rem(155px);
            height: px2rem(155px);
            margin: 0 auto 10px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    .form-box {
        flex: 1;
        overflow: hidden;
        padding: px2rem(50px) 0;
        form {
            display: block;
            padding: px2rem(40px) px2rem(50px);
            background-color: #fff;
            box-sizing: border-box;
            width: 100%;
            box-shadow: 0 30px 20px -20px rgba(0, 0, 0, .1);
        }
        .row {
            display: flex;
            .cell {
                + .cell {
                    margin-left: 10px;
                }
            }
            &.full {
                display: block;
                .cell {
                    display: block;
                }
            }
        }
        .cell {
            margin-bottom: 10px;
            flex: 1;
            overflow: hidden;
            display: flex;
            background-color: #f5f5f5;
        }
        .word {
            padding-left: 20px;
            font-size: 14px;
            line-height: 46px;
            color: #333;
            margin-right: 10px;
        }
        .input {
            flex: 1;
            overflow: hidden;
            position: relative;
            input {
                display: block;
                width: 100%;
                height: 46px;
                line-height: 46px;
                font-size: 14px;
                color: #333;
                appearance: none;
                border: none;
                background: none;
            }
        }
        .text {
            padding: 0 20px;
            textarea {
                display: block;
                width: 100%;
                font-size: 14px;
                line-height: 30px;
                color: #333;
                appearance: none;
                background: none;
                border: none;
                resize: none;
            }
        }
        .captcha {
            margin-bottom: 10px;
            width: 100%;
        }
        button {
            display: block;
            width: 200px;
            height: 42px;
            text-align: center;
            line-height: 42px;
            color: #fff;
            background: $blue;
            font-size: 14px;
            border: none;
            appearance: none;
            transition: all .2s;
            cursor: pointer;
            &:hover {
                background: lighten($blue, 5%);
            }
        }
        select {
            opacity: 0;
            z-index: 5;
            cursor: pointer;
            font-size: 14px;
        }
        .shop-select {
            width: 100%;
            height: 46px;
            line-height: 46px;
            box-sizing: border-box;
            position: relative;
            padding-right: 20px;
            &:after {
                position: absolute;
                display: block;
                width: 4px;
                height: 4px;
                border-left: 1px solid #818181;
                border-bottom: 1px solid #818181;
                content: '';
                right: 10px;
                top: 50%;
                transform: rotate(-45deg);
                margin-top: -2px;
            }
            .customSelectInner {
                font-size: 14px;
                color: #333;
            }
        }
    }
    @media (max-width: 900px) {
        background-position: center top;
        background-size: 100% auto;
        display: block;
        .ewm {
            width: 100%;
            padding-top: px2rem(40px);
        }
        .form-box {
            .row {
                display: block;
                .cell + .cell {
                    margin-left: 0;
                }
            }
        }
    }
}