.policy-con {
    padding-top: px2rem(40px);
    background: #f5f5f5;
    overflow: hidden;
}
.policy-box {
    position: relative;
    padding: px2rem(70px) 0;
    overflow: hidden;
    .bg {
        position: absolute;
        width: 100%;
        height: 720px;
        left: 0;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(to right, #fff, #dce2e6);
    }
    .article {
        position: relative;
        z-index: 2;
        font-size: 13px;
        line-height: 24px;
        color: #666;
        overflow: hidden;
        img {
            max-width: 100%;
            height: auto !important;
        }
        p {
            margin-bottom: 15px;
        }
        .t {
            text-align: center;
            font-size: 30px;
            color: #333;
            font-family: '方正大黑简体';
            margin-bottom: px2rem(40px);
        }
        .table {
            position: relative;
            padding-bottom: 40px;
            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 131px;
                background-image: url(../images/shadow2.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                transform: translateY(5%);
                content: '';
            }
            table {
                position: relative;
                z-index: 2;
                background-color: #fff;
                width: 100%;
                min-width: 700px;
                td {
                    border: 1px solid #e5e5e5;
                    box-sizing: border-box;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 14px;
                    color: #666;
                    padding: px2rem(20px) px2rem(40px);
                    min-height: 70px;
                    &.bg-fa {
                        background-color: #fafafa;
                    }
                }
                th {
                    border: 1px solid #e5e5e5;
                    box-sizing: border-box;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 16px;
                    color: #666;
                    padding: px2rem(20px) px2rem(40px);
                    min-height: 70px;
                }
            }
        }
        .flexbox {
            display: flex;
            align-items: center;
            .icon {
                width: px2rem(127px);
                height: px2rem(127px);
                margin-right: px2rem(30px);
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .info {
                flex: 1;
                overflow: hidden;
            }
            .row {
                margin-bottom: 15px;
                .cell-2 {
                    &:nth-child(2n+1) {
                        padding-right: 10px;
                    }
                    &:nth-child(2n) {
                        padding-left: 10px;
                    }
                }
            }
            .cell-2 {
                float: left;
                width: 50%;
                box-sizing: border-box;
            }
        }
    }
    @media (max-width: 750px) {
        .article {
            .t {
                font-size: 22px;
            }
            .table-box {
                overflow-x: scroll;
            }
            .flexbox {
                display: block;
                .icon {
                    margin-bottom: 10px;
                }
            }
        }
    }
}