.culture-con {
    padding: px2rem(40px) 0 px2rem(50px);
    .article {
        width: 1030px;
        max-width: 100%;
        margin: 0 auto px2rem(30px);
        font-size: 14px;
        line-height: 24px;
        color: #777;
    }
    .culture-list {
        display: flex;
        .item {
            flex: 1;
            overflow: hidden;
            padding-bottom: 20px;
            position: relative;
            &:nth-child(3n+2) {
                margin: 0 20px;
            }
            &:after {
                width: 100%;
                height: 0;
                padding-top: (102/466) * 100%;
                background-image: url(../images/shadow1.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                content: '';
                left: 0;
                bottom: -10px;
                position: absolute;
                display: block;
            }
        }
        .wp {
            position: relative;
            z-index: 2;
            background-color: #fff;
            height: 100%;
        }
        .img {
            width: 100%;
            position: relative;
            padding-top: (258/438) * 100%;
            overflow: hidden;
            @include imgCommon();
        }
        .info {
            position: relative;
            z-index: 2;
            padding: px2rem(60px) px2rem(40px) px2rem(20px);
        }
        .icon {
            width: px2rem(90px);
            height: px2rem(90px);
            left: 50%;
            margin-left: px2rem(-45px);
            top: px2rem(-45px);
            position: absolute;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .t {
            text-align: center;
            font-size: 24px;
            color: #333;
            margin-bottom: 10px;
            a {
                color: #333;
                &:hover {
                    color: $blue;
                }
            }
        }
        .p {
            font-size: 14px;
            line-height: 24px;
            color: #888;
            p {
                margin-bottom: 10px;
            }
        }
        @media (max-width: 900px) {
            display: block;
            .item {
                &:nth-child(3n+2) {
                    margin: 0;
                }
            }
        }
    }
}