.incorrupt-con {
    padding: px2rem(70px) 0;
    background-color: #f5f5f5;
}
.incorrupt-box {
    display: flex;
    background-color: #fff;
    margin-bottom: px2rem(30px);
    .list {
        flex: 1025;
        overflow: hidden;
        font-size: 0;
        .li {
            width: 33.33%;
            display: inline-flex;
            vertical-align: top;
            height: 235px;
            align-items: center;
            box-shadow: 10px 4px 30px rgba(#555, .1);
        }
        .wp {
            padding: 0 (40/340) * 100% 0 (50/340) * 100%;
            display: flex;
            width: 100%;
            box-sizing: border-box;
            align-items: center;
        }
        .icon {
            width: px2rem(80px);
            height: px2rem(80px);
            margin-right: 10px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
        }
        .t {
            font-size: 20px;
            color: #333;
            line-height: 30px;
        }
        .p {
            font-size: 14px;
            line-height: 18px;
            color: #888;
        }
    }
    .ewm {
        flex: 335;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 10px 4px 30px rgba(#555, .1);
        .img {
            padding: 0 10px;
            text-align: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    @media (max-width: 900px) {
        display: block;
        .list {
            .li {
                width: 50%;
            }
            .wp {
                padding: 0 px2rem(20px);
            }
        }
        .ewm {
            .img {
                padding: px2rem(20px);
            }
        }
    }
    @media (max-width: 750px) {
        .list {
            .li {
                height: 175px;
            }
            .t {
                font-size: 18px;
                line-height: 24px;
            }
            .p {
                font-size: 12px;
            }
        }
    }
}
.incorrupt-intro {
    display: flex;
    box-shadow: 5px 0 30px rgba(#555, .1);
    .item {
        flex: 1;
        display: flex;
        min-height: 310px;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        background-color: #fff;
        box-sizing: border-box;
        padding: px2rem(30px);
        overflow: hidden;
        + .item {
            box-shadow: -10px 4px 25px rgba(#555, .1);
        }
    }
    .icon {
        width: px2rem(82px);
        height: px2rem(82px);
        margin: 0 auto 10px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .title {
        text-align: center;
        font-size: 20px;
        color: #000;
        margin-bottom: 8px;
    }
    .p {
        max-width: 310px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 30px;
        color: #777;
    }
    @media (max-width: 750px) {
        display: block;
        .item {
            display: block;
            min-height: 0;
        }
    }
}