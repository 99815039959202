.sitemap-con {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/bg3.jpg);
    padding: px2rem(110px) 0 px2rem(60px);
}
.sitemap-box {
    box-sizing: border-box;
    background-color: #fff;
    padding: px2rem(80px) px2rem(50px) px2rem(40px);
    font-size: 0;
    .item {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        padding: 0 22px;
        box-sizing: border-box;
    }
    .top,
    .dt {
        margin-bottom: 5px;
        a {
            display: block;
            width: 100%;
            background: $blue;
            color: #fff;
            font-size: 14px;
            box-sizing: border-box;
            padding: 10px;
            line-height: 24px;
            text-align: center;
            transition: all .2s;
            &:hover {
                background: lighten($blue, 5%);
            }
        }
    }
    .dl {
        .dd {
            &:last-child {
                margin-bottom: 15px;
            }
        }
    }
    .dd {
        margin-bottom: 5px;
        a {
            display: block;
            width: 100%;
            background: #79aab6;
            color: #fff;
            font-size: 14px;
            box-sizing: border-box;
            padding: 10px;
            line-height: 24px;
            text-align: center;
            &:hover {
                background: lighten(#79aab6, 5%);
            }
        }
    }
    @media (max-width: 1024px) {
        .item {
            padding: 0 10px;
        }
    }
    @media (max-width: 900px) {
        padding-right: px2rem(30px);
        padding-left: px2rem(30px);
    }
    @media (max-width: 750px) {
        .item {
            width: 100%;
        }
    }
}