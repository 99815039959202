.index-banner {
    .swiper-slide {
        .img {
            display: block;
            width: 100%;
            position: relative;
            padding-top: (800/1920) * 100%;
            overflow: hidden;
            @include imgCommon();
        }
    }
    .swiper-pagination {
        &-bullet {
            opacity: 1;
            cursor: pointer;
            outline: none;
            background: #fff;
            &-active {
                background: $blue;
            }
        }
    }
}
.index-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: px2rem(40px);
    .t {
        line-height: 50px;
        .cn {
            font-size: 32px;
            font-family: '方正大黑简体';
        }
        .en {
            text-transform: uppercase;
            font-size: 34px;
        }
    }
    .more {
        line-height: 50px;
        color: #999;
        display: block;
        font-family: Arial;
        font-size: 16px;
        &:hover {
            color: $blue;
        }
    }
    @media (max-width: 900px) {
        display: block;
        margin-bottom: px2rem(30px);
        .t {
            text-align: center;
            .en {
                display: none;
            }
        }
        .more {
            display: none;
        }
    }
    @media (max-width: 750px) {
        .t {
            line-height: 32px;
            .cn {
                font-size: 20px;
            }
        }
    }
}
.index-product {
    background-color: #fff;
    padding: 120px 0;
    &-hd {
        font-size: 0;
        .item {
            position: relative;
            display: inline-flex;
            vertical-align: top;
            line-height: 50px;
            align-items: center;
            padding: 0 px2rem(40px);
            font-size: 20px;
            color: #333;
            cursor: pointer;
            &:before {
                position: absolute;
                left: 0;
                top: 50%;
                width: 1px;
                height: 10px;
                display: block;
                margin-top: -5px;
                background: #ccc;
                content: '';
            }
            &:first-child {
                &:before {
                    display: none;
                }
            }
            img {
                margin-right: 5px;
                width: px2rem(39px);
                height: px2rem(39px);
            }
            .front {
                display: block;
            }
            .back {
                display: none;
            }
            &.on {
                color: $blue;
                .front {
                    display: none;
                }
                .back {
                    display: block;
                }
            }
        }
    }
    &-bd {
        .box {
            display: none;
            &:first-child {
                display: block;
            }
            .wp {
                display: flex;
                width: 100%;
            }
            .big-img {
                display: block;
                flex: 715;
                overflow: hidden;
                position: relative;
                .swiper-container {
                    width: 100%;
                    .swiper-slide {
                        a {
                            display: block;
                            position: relative;
                            &:before {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                z-index: 2;
                                background-image: url(../images/shadow3.png);
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: 100% 100%;
                                content: '';
                            }
                        }
                    }
                }
                .swiper-pagination {
                    z-index: 10;
                    &-bullet {
                        background: #fff;
                        opacity: 1;
                        cursor: pointer;
                        outline: none;
                        &-active {
                            background: $blue;
                        }
                    }
                }
                &:hover {
                    .img {
                        &:after {
                            opacity: 1;
                        }
                    }
                    .t {
                        color: $blue;
                        transform: translateY(-10px);
                    }
                }
                .img {
                    width: 100%;
                    padding-top: (600/715) * 100%;
                    position: relative;
                    overflow: hidden;
                    @include imgCommon();
                    &:after {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        content: '';
                        background-color: rgba(#000, .3);
                        display: block;
                        transition: all .4s;
                        opacity: 0;
                    }
                }
                .t {
                    position: absolute;
                    z-index: 3;
                    text-align: center;
                    color: #fff;
                    font-size: 36px;
                    width: 100%;
                    left: 0;
                    bottom: (50/600) * 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    box-sizing: border-box;
                    padding: 0 px2rem(20px);
                    transition: all .4s;
                }
            }
            .small-list {
                flex: 1920 - 715;
                font-size: 0;
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                border-top: 1px solid #f0f0f0;
                border-bottom: 1px solid #f0f0f0;
                position: relative;
                z-index: 2;
                li {
                    width: 50%;
                    height: 50%;
                    display: inline-flex;
                    vertical-align: top;
                    a {
                        display: flex;
                        flex: 1;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        &:hover {
                            .img {
                                background-color: darken(#e2e2e2, 5%);
                                box-shadow: inset 0 0 20px rgba(#000, .1);
                                img {
                                    transform: translate(-50%, -50%) scale(1.05, 1.05);
                                }
                            }
                            .t {
                                color: $blue;
                            }
                        }
                    }
                    .img {
                        flex: 1;
                        position: relative;
                        height: 100%;
                        overflow: hidden;
                        background-color: #e2e2e2;
                        transition: all .4s;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            max-width: 100%;
                            max-height: 100%;
                            //object-fit: contain;
                            transition: all .5s;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .info {
                        flex: 1;
                        display: flex;
                        height: 100%;
                        align-items: center;
                    }
                    .wrapper {
                        box-sizing: border-box;
                        width: 100%;
                        padding: px2rem(20px);
                        text-align: center;
                    }
                    .t {
                        font-size: 18px;
                        color: #333;
                        margin-bottom: 3px;
                        transition: all .2s;
                    }
                    .p {
                        font-size: 14px;
                        color: #b3b3b3;
                    }
                    .iconfont {
                        display: block;
                        margin: px2rem(20px) auto 0;
                        width: 26px;
                        height: 26px;
                        border-radius: 100%;
                        border: 1px solid $blue;
                        text-align: center;
                        line-height: 26px;
                        font-size: 14px;
                        color: $blue;
                        transform: rotate(180deg);
                    }
                    &:nth-child(4n+3),
                    &:nth-child(4n+4) {
                        a {
                            flex-direction: row-reverse;
                        }
                        .iconfont {
                            transform: rotate(0);
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1024px) {
        &-hd {
            .item {
                padding: 0 px2rem(20px);
                font-size: 18px;
            }
        }
        &-bd {
            .box {
                .big-img {
                    .t {
                        font-size: 24px;
                    }
                }
                .small-list {
                    li {
                        .t {
                            font-size: 14px;
                        }
                        .p {
                            font-size: 12px;
                        }
                        .iconfont {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 900px) {
        padding: px2rem(70px) px2rem(30px);
        &-hd {
            text-align: center;
        }
        &-bd {
            .box {
                .wp {
                    display: block;
                }
                .big-img {
                    width: 100%;
                }
                .small-list {
                    display: block;
                    width: 100%;
                    border: 1px solid #f0f0f0;
                    li {
                        width: 100%;
                        height: px2rem(345px);
                        .iconfont {
                            transform: rotate(0);
                        }
                        &:nth-child(4n+3),
                        &:nth-child(4n+4) {
                            a {
                                flex-direction: row;
                            }
                        }
                        &:nth-child(2n+1) {
                            a {
                                flex-direction: row-reverse;
                            }
                        }
                        &:nth-child(2n) {
                            .iconfont {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 750px) {
        &-hd {
            .item {
                font-size: 14px;
                line-height: 40px;
            }
        }
        &-bd {
            .box {
                .big-img {
                    .t {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    @media (max-width: 500px) {
        &-hd {
            .item {
                display: inline-flex;
                width: 40%;
                text-align: center;
                justify-content: center;
                align-items: center;
                &:nth-child(2n+1) {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}
.index-recommend {
    position: relative;
    width: 100%;
    display: block;
    .swiper-slide {
        a {
            display: block;
            &:hover {
                .img img {
                    transform: scale(1.02);
                }
            }
        }
    }
    .img {
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 790px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            transition: all .5s;
        }
    }
    .info {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        z-index: 2;
        background: rgba(#57c3df, .3);
        text-align: center;
        padding: px2rem(45px) px2rem(20px) px2rem(35px);
        box-sizing: border-box;
    }
    .title {
        margin-bottom: 10px;
        i {
            display: inline-block;
            vertical-align: top;
            width: 46px;
            height: 46px;
            border-radius: 100%;
            background: $blue;
            color: #fff;
            text-align: center;
            line-height: 46px;
            margin-right: 5px;
            font-size: 20px;
        }
        .t {
            display: inline-block;
            vertical-align: top;
            line-height: 46px;
            color: #fff;
            font-size: 36px;
        }
    }
    .p {
        font-size: 14px;
        line-height: 26px;
        color: #fff;
        margin: 0 auto;
        max-width: 630px;
    }
    .index-recommend-prev,
    .index-recommend-next {
        position: absolute;
        top: 50%;
        font-size: 40px;
        color: #fff;
        z-index: 5;
        cursor: pointer;
        outline: none;
        font-weight: bold;
        line-height: 1;
        margin-top: -20px;
    }
    .index-recommend-prev {
        left: px2rem(30px);
        transform: rotate(180deg);
    }
    .index-recommend-next {
        right: px2rem(30px);
    }
    @media (max-width: 1024px) {
        .img {
            height: 560px;
        }
    }
    @media (max-width: 750px) {
        .img {
            height: px2rem(790px);
        }
        .title {
            i {
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 12px;
            }
            .t {
                line-height: 24px;
                font-size: 18px;
            }
        }
        .p {
            line-height: 22px;
        }
        .index-recommend-prev,
        .index-recommend-next {
            font-size: 20px;
            margin-top: -10px;
        }
    }
}
.index-offers {
    padding: px2rem(120px) 0;
    background-color: #fff;
    &-list {
        margin-right: -26px;
        margin-left: -26px;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            box-sizing: border-box;
            padding: 0 26px;
            a {
                display: block;
                &:hover {
                    .img img {
                        transform: scale(1.04);
                    }
                    .info {
                        transform: translateY(-10px);
                    }
                }
            }
            .img {
                position: relative;
                width: 100%;
                padding-top: (297/497) * 100%;
                overflow: hidden;
                @include imgCommon();
            }
            .info {
                position: relative;
                background-color: #fff;
                z-index: 2;
                margin: -25px 22px 0;
                box-sizing: border-box;
                padding: px2rem(30px) px2rem(65px);
                box-shadow: 0 0 24px rgba(0, 0, 0, .1);
                transition: all .5s;
            }
            .t {
                font-size: 22px;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 5px;
            }
            .p {
                font-size: 14px;
                color: #888;
                line-height: 24px;
                height: 48px;
                overflow: hidden;
                margin-bottom: 10px;
            }
            .arrow {
                width: 26px;
                height: 26px;
                border-radius: 100%;
                text-align: center;
                line-height: 26px;
                color: #fff;
                background: $blue;
                font-size: 14px;
            }
        }
        @media (max-width: 1440px) {
            li {
                .info {
                    padding: px2rem(30px) px2rem(40px);
                }
            }
        }
        @media (max-width: 1024px) {
            margin-right: -13px;
            margin-left: -13px;
            li {
                padding: 0 13px;
                .info {
                    padding: px2rem(20px);
                }
            }
        }
    }
    .m-more {
        display: none;
        text-align: center;
        font-size: px2rem(24px);
        color: #999;
        font-family: Arial;
    }
    @media (max-width: 900px) {
        padding: px2rem(70px) 0 px2rem(60px);
        background-color: #e6e6e6;
        &-list {
            margin-right: -5px;
            margin-left: -5px;
            li {
                padding: 0 5px;
                width: 50%;
                margin-bottom: px2rem(40px);
                &:first-child {
                    width: 100%;
                }
            }
        }
        .m-more {
            display: block;
        }
    }
    @media (max-width: 750px) {
        &-list {
            li {
                .info {
                    margin-right: 10px;
                    margin-left: 10px;
                    margin-top: -10px;
                }
                .t {
                    text-align: center;
                    font-size: 14px;
                }
                .p {
                    font-size: 10px;
                    line-height: 20px;
                    height: auto;
                    max-height: 40px;
                    text-align: center;
                }
                .arrow {
                    margin: 0 auto;
                }
            }
        }
    }
}
.index-brand {
    position: relative;
    background-color: #fff;
    .img {
        position: relative;
        width: 100%;
        padding-top: (770/1920) * 100%;
        overflow: hidden;
        cursor: pointer;
        @include imgCommon();
        .pc-img {
            display: block;
        }
        .m-img {
            display: none;
        }
        &:hover {
            img {
                transform: translate3d(0, 0, 0) scale(1.01);
            }
        }
    }
    .info {
        position: absolute;
        z-index: 2;
        top: 0;
        right: px2rem(116px);
        background: rgba(#fff, .5);
        width: 420px;
        box-sizing: border-box;
        padding: px2rem(50px);
        .cn {
            font-family: '方正大黑简体';
            font-size: 32px;
        }
        .en {
            font-size: 34px;
            line-height: 34px;
            color: #333;
            text-transform: uppercase;
        }
        .p {
            font-size: 14px;
            line-height: 30px;
            color: #666;
            margin-top: px2rem(20px);
        }
    }
    @media (max-width: 1024px) {
        .info {
            padding: px2rem(30px);
        }
    }
    @media (max-width: 900px) {
        padding: px2rem(80px) px2rem(30px) px2rem(70px);
        .info {
            position: static;
            padding: 0;
            width: 100%;
            text-align: center;
            .en {
                display: none;
            }
            .p {
                height: 90px;
                overflow: hidden;
                margin-bottom: 10px;
            }
        }
        .img {
            padding-top: (400/695) * 100%;
            .pc-img {
                display: none;
            }
            .m-img {
                display: block;
            }
        }
    }
    @media (max-width: 750px) {
        .info {
            .cn {
                font-size: 20px;
                line-height: 32px;
            }
        }
    }
}
.index-video-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(#000, .5);
    top: 0;
    left: 0;
    z-index: 104;
    display: none;
    .box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1080px;
        height: 720px;
        background-color: #000;
        max-width: 100%;
        max-height: 100%;
        //overflow: hidden;
    }
    .video,
    video {
        display: block;
        width: 100%;
        height: 100%;
        outline: none;
        overflow: hidden;
    }
    .index-video-close {
        position: absolute;
        color: #fff;
        font-size: 24px;
        right: -10px;
        top: -10px;
        z-index: 5;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
    }
    @media (max-width: 1024px) {
        background-color: #000;
        .box {
            box-sizing: border-box;
            padding: 30px 0;
        }
        .index-video-close {
            top: 0;
            right: 5px;
        }
    }
}
.index-news-con {
    background-color: #fff;
    padding: px2rem(110px) 0;
    .m-more {
        display: none;
        text-align: center;
        font-size: px2rem(24px);
        color: #999;
        font-family: Arial;
    }
    @media (max-width: 900px) {
        background-color: #e6e6e6;
        padding: px2rem(80px) 0 px2rem(70px);
        .m-more {
            display: block;
        }
    }
}
.index-news-top {
    margin-bottom: px2rem(60px);
    a {
        display: flex;
        //align-items: center;
        &:hover {
            .img span img {
                transform: scale(1.04);
            }
            .info {
                &:after {
                    width: 100%;
                }
            }
            .t {
                color: $blue;
            }
        }
    }
    .img {
        width: 530px;
        margin: 20px 0;
        position: relative;
        z-index: 2;
        span {
            display: block;
            width: 100%;
            position: relative;
            padding-top: (320/530) * 100%;
            overflow: hidden;
            @include imgCommon();
        }
    }
    .wp {
        background: #f5f5f5;
        flex: 1;
        overflow: hidden;
        position: relative;
        margin-left: -40px;
        display: flex;
        //height: 100%;
        align-items: center;
        width: 100%;
    }
    .info {
        margin-left: px2rem(130px);
        margin-right: px2rem(84px);
        flex: 1;
        overflow: hidden;
        padding-bottom: px2rem(30px);
        position: relative;
        border-bottom: 1px solid #c4c4c4;
        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            background: $blue;
            width: 40px;
            height: 3px;
            content: '';
            display: block;
            transition: all .4s;
        }
    }
    .t {
        font-size: 24px;
        color: #333;
        margin-bottom: px2rem(15px);
        transition: all .2s;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .p {
        font-size: 14px;
        line-height: 30px;
        color: #777;
        max-height: 120px;
        overflow: hidden;
    }
    .time {
        margin-right: px2rem(60px);
        color: $blue;
        font-family: '方正大黑简体';
    }
    .date {
        font-size: 48px;
    }
    .year {
        font-size: 16px;
    }
    .mobile-time {
        display: none;
        color: $blue;
        font-size: 14px;
        margin-top: px2rem(10px);
    }
    @media (max-width: 1440px) {
        .info {
            margin-left: px2rem(90px);
            margin-right: px2rem(50px);
        }
    }
    @media (max-width: 900px) {
        margin-bottom: 0;
        a {
            display: block;
        }
        .img {
            width: 100%;
            margin: 0;
        }
        .wp {
            margin-left: 0;
            display: block;
            background: none;
        }
        .info {
            margin-right: px2rem(30px);
            margin-left: px2rem(30px);
            padding-top: px2rem(30px);
        }
        .time {
            display: none;
        }
        .p {
            line-height: 24px;
            height: 72px;
        }
        .mobile-time {
            display: block;
        }
        .t {
            font-size: 18px;
        }
    }
}
.index-news-list {
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        padding: 0 px2rem(50px);
        a {
            display: block;
            position: relative;
            border-bottom: 1px solid #c4c4c4;
            padding-bottom: px2rem(30px);
            &:hover {
                &:after {
                    width: 100%;
                }
                .t {
                    color: $blue;
                }
            }
            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                background: $blue;
                width: 40px;
                height: 3px;
                content: '';
                display: block;
                transition: all .4s;
            }
        }
        .t {
            font-size: 18px;
            color: #000;
            margin-bottom: px2rem(15px);
            transition: all .2s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .p {
            font-size: 14px;
            line-height: 30px;
            color: #888;
            height: 90px;
            overflow: hidden;
            margin-bottom: 10px;
        }
        .time {
            color: $blue;
            font-size: 24px;
        }
    }
    @media (max-width: 900px) {
        margin-bottom: px2rem(40px);
        li {
            width: 100%;
            padding: px2rem(30px) px2rem(30px) 0;
            .p {
                line-height: 24px;
                height: 72px;
            }
            .time {
                font-size: 14px;
            }
        }
    }
}