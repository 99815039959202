.product-list-banner {
    .swiper-slide {
        .img {
            position: relative;
            width: 100%;
            padding-top: (580/1920) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
    }
    .swiper-pagination {
        &-bullet {
            opacity: 1;
            background: #fff;
            outline: none;
            cursor: pointer;
            &-active {
                background: $blue;
            }
        }
    }
}
.product-list-con {
    background: #f5f5f5;
    padding: px2rem(50px) 0 px2rem(70px);
}
.product-list-title {
    border-left: 5px solid $blue;
    padding-left: 10px;
    line-height: 32px;
    font-family: '方正大黑简体';
    font-size: 32px;
    color: #333;
    margin-bottom: px2rem(30px);
    @media (max-width: 750px) {
        font-size: 24px;
    }
}
.product-list-tip {
    margin-bottom: px2rem(30px);
    font-size: 14px;
    color: #333;
}
.product-list-menu {
    border-bottom: 1px solid #ababab;
    margin-bottom: px2rem(30px);
    box-sizing: border-box;
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        width: auto;
        a {
            display: block;
            padding: 0 px2rem(40px) px2rem(30px);
            position: relative;
            font-size: 16px;
            color: #333;
            @media (max-width: 750px) {
                font-size: 14px;
            }
            &:hover {
                color: $blue;
            }
            &:after {
                position: absolute;
                right: 0;
                width: 1px;
                height: 10px;
                background: #bfbfbf;
                top: 4px;
                content: '';
                display: block;
            }
        }
        &:first-child {
            a {
                padding-left: 0;
            }
        }
        &:last-child {
            a {
                &:after {
                    display: none;
                }
            }
        }
        &.on {
            a {
                color: $blue;
                &:before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background: $blue;
                    content: '';
                    display: block;
                }
            }
        }
    }
}
.product-list {
    margin-right: -10px;
    margin-left: -10px;
    font-size: 0;
    padding-bottom: px2rem(20px);
    li {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        padding: 0 10px;
        margin-bottom: px2rem(24px);
        box-sizing: border-box;
        a {
            display: block;
            background: #fff;
            transition: all .4s;
            &:hover {
                box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
                transform: translateY(-10px);
                .img img {
                    transform: scale(1.03);
                }
                .t {
                    color: $blue;
                }
            }
        }
        .img {
            width: 100%;
            padding-top: (245/325) * 100%;
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: contain;
                transition: all .5s;
            }
        }
        .info {
            padding: 10px 10px px2rem(50px);
            text-align: center;
        }
        .t {
            font-size: 18px;
            line-height: 24px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: all .2s;
        }
        .p {
            font-size: 14px;
            color: #b3b3b3;
            line-height: 24px;
            height: 48px;
            overflow: hidden;
        }
        .arrow {
            margin: 0 auto;
            width: 28px;
            height: 28px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    @media (max-width: 750px) {
        margin-right: -5px;
        margin-left: -5px;
        li {
            padding: 0 5px;
            width: 50%;
            .t {
                font-size: 16px;
            }
            .p {
                font-size: 12px;
                line-height: 22px;
                height: 44px;
            }
        }
    }
}
.product-detail-top {
    background: #f0f1f4;
    padding: px2rem(50px) 0;
    .top-flex {
        padding-top: px2rem(50px);
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 1024px) {
        .top-flex {
            display: block;
        }
    }
}
.product-detail-swiper {
    width: 710px;
    max-width: 100%;
    @media (max-width: 1024px) {
        margin: 0 auto px2rem(30px);
    }
}
.product-detail-big {
    width: 100%;
    max-height: 410px;
    overflow: hidden;
    margin-bottom: px2rem(50px);
    .swiper-slide {
        .img {
            width: 100%;
            padding-top: (410/710) * 100%;
            position: relative;
            overflow: hidden;
            @include imgCommon(contain)
        }
    }
}
.product-detail-tip {
    text-align: center;
    font-size: 14px;
    color: #333;
    margin-top: px2rem(14px);
}
.product-detail-thumb {
    margin: 0 auto;
    width: 370px;
    max-width: 100%;
    display: flex;
    .prev,
    .next {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #333;
        cursor: pointer;
        width: 40px;
        justify-content: center;
        text-align: center;
        outline: none;
        &:hover {
            color: $blue;
        }
    }
    .prev {
        transform: rotate(180deg);
    }
    .swiper-container {
        flex: 1;
        width: 100%;
        .swiper-slide {
            width: 60px;
            height: 60px;
            cursor: pointer;
            box-sizing: border-box;
            .img {
                position: absolute;
                width: 100%;
                height: 100%;
                border: 1px solid #bdced4;
                background: #e0eef2;
                box-sizing: border-box;
                @include imgCommon(contain)
            }
            &.swiper-slide-thumb-active {
                .img {
                    border-color: $blue;
                }
            }
        }
    }
}
.product-detail-intro {
    width: 450px;
    max-width: 100%;
    .t {
        font-size: 38px;
        color: #000;
        font-family: '方正大黑简体';
    }
    .sub {
        font-family: Arial;
        color: #000;
        font-size: 18px;
        margin-bottom: 15px;
    }
    .prices {
        //margin-top: px2rem(70px);
        font-size: 16px;
        color: #000;
        margin-bottom: 10px;
        line-height: 24px;
        strong {
            font-weight: normal;
            font-size: 36px;
        }
    }
    .tip {
        font-size: 14px;
        line-height: 24px;
        color: #666;
        img {
            vertical-align: middle;
            margin-right: 3px;
        }
    }
    .bottom {
        margin-top: px2rem(50px);
    }
    .buy-btn {
        display: inline-block;
        vertical-align: top;
        width: 220px;
        height: 56px;
        border-radius: 28px;
        text-align: center;
        line-height: 56px;
        color: #fff;
        background: $blue;
        font-size: 20px;
        &:hover {
            background: darken($blue, 5%);
        }
    }
    .mobile-qr {
        display: inline-block;
        vertical-align: top;
        position: relative;
        line-height: 56px;
        font-size: 14px;
        margin-left: 20px;
        cursor: pointer;
        &:hover {
            .qr {
                display: block;
            }
        }
        &:before {
            font-family: 'iconfont';
            content: '\e602';
            margin-right: 5px;
            font-size: 24px;
            display: inline-block;
            vertical-align: middle;
        }
        .qr {
            position: absolute;
            width: 150px;
            height: 150px;
            background-color: #fff;
            padding: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            bottom: 56px;
            left: 0;
            z-index: 10;
            display: none;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    @media (max-width: 1250px) {
        flex: 1;
        overflow: hidden;
        margin-left: px2rem(20px);
    }
    @media (max-width: 1024px) {
        width: 100%;
        margin-left: 0;
        .mobile-qr {
            display: none;
        }
    }
    @media (max-width: 750px) {
        .t {
            font-size: 22px;
        }
        .bottom {
            text-align: right;
        }
    }
}
.product-detail-bottom {
    background-color: #fff;
    padding: px2rem(50px) 0 px2rem(80px);
}
.product-detail-hd {
    height: 78px;
    &-wp {
        border-bottom: 1px solid #d1d1d1;
        text-align: center;
        font-size: 0;
        background-color: #fff;
        position: relative;
        &.fixed {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 201;
        }
    }
    .item {
        display: inline-block;
        vertical-align: top;
        width: 260px;
        height: 78px;
        position: relative;
        font-size: 16px;
        color: #000;
        line-height: 78px;
        max-width: 25%;
        cursor: pointer;
        &.on {
            color: $blue;
            &:after {
                position: absolute;
                bottom: 0;
                width: 180px;
                height: 5px;
                background: $blue;
                content: '';
                display: block;
                left: 50%;
                max-width: 100%;
                transform: translateX(-50%);
            }
        }
    }
    @media (max-width: 750px) {
        .item {
            height: 50px;
            line-height: 50px;
            font-size: 14px;
        }
    }
}
.product-detail-bd {
    .box {
        display: none;
        &:first-child {
            display: block;
        }
    }
}
.product-detail-tags {
    font-size: 0;
    margin-bottom: px2rem(30px);
    .item {
        display: inline-block;
        vertical-align: top;
        padding: 0 20px;
        line-height: 36px;
        margin-right: 8px;
        margin-bottom: 2px;
        background-color: #eee;
        color: #555;
        cursor: pointer;
        transition: all .2s;
        font-size: 14px;
        &:hover {
            color: #fff;
            background-color: $blue;
        }
    }
}
.product-article-list {
    padding-bottom: px2rem(60px);
    .item {
        background-color: #fff;
        padding: px2rem(100px) 0;
        //margin-top: px2rem(60px);
        .wp770 {
            width: 1040px;
            max-width: 100%;
            margin: 0 auto;
            display: flex;
            //justify-content: space-between;
            align-items: center;
            //box-shadow: 0 0 10px rgba(#000, .2);
            padding-bottom: 50px;
            position: relative;
            &:after {
                position: absolute;
                left: 120px;
                top: 50px;
                bottom: 0;
                right: 0;
                background: #fff;
                content: '';
                display: block;
                box-shadow: 0 0 20px rgba(#000, .2);
            }
        }
        .img {
            max-width: 100%;
            width: 377px;
            height: 377px;
            background-color: #fff;
            position: relative;
            z-index: 2;
            img {
                //max-width: 100%;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
            //width: 530px;
            //max-width: 100%;
            box-sizing: border-box;
            margin: 0 px2rem(80px) 0 px2rem(100px);
            //overflow: hidden;
            position: relative;
            z-index: 2;
            .t {
                font-size: 42px;
                font-family: '方正大黑简体';
                margin-bottom: 10px;
                color: $blue;
            }
            .p {
                //font-size: 18px;
                font-size: 16px;
                line-height: 30px;
                color: #333;
                text-align: left;
            }
            &:before {
                //position: absolute;
                width: 22px;
                height: 16px;
                background-image: url(../images/icon26.png);
                background-repeat: no-repeat;
                background-position: center center;
                display: block;
                margin-bottom: px2rem(40px);
                content: '';
            }
        }
        &:nth-child(2n) {
            background-color: #f5f5f5;
            .wp770 {
                flex-direction: row-reverse;
                &:after {
                    left: 0;
                    right: 100px;
                }
                .img {
                    margin-left: auto;
                    margin-right: -20px;
                }
            }
        }
        @media (max-width: 1120px) {
            .wp770 {
                width: auto;
                margin: 0 px2rem(30px);
            }
        }
        @media (max-width: 900px) {
            .wp770 {
                display: block;
                &:after {
                    left: 0;
                }
            }
            .img {
                margin: 0 auto 30px;
            }
            .info {
                margin: 0 px2rem(40px);
                .t {
                    font-size: 24px;
                    margin-bottom: 15px;
                }
                .p {
                    font-size: 14px;
                    line-height: 28px;
                }
                &:before {
                    top: -20px;
                }
            }
            &:nth-child(2n) {
                .wp770 {
                    &:after {
                        right: 0;
                    }
                    .img {
                        margin: 0 auto 30px;
                    }
                }
            }
        }
        @media (max-width: 400px) {
            .img {
                width: 100%;
                height: auto;
            }
        }
    }
}
.product-article {
    line-height: 30px;
    color: #333;
    padding: px2rem(50px) 0;
    @include articleReset(16px, 30px);
}