@import url(//at.alicdn.com/t/font_2073822_9u18pfmqhl.css);
/* CSS Document */
//SCSS全局变量
$blue: #59c3e1;
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}
// 多行省略
@mixin multiEllipsis($line: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
@mixin imgCommon($fit: cover) {
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all .5s;
        object-fit: $fit;
    }
}
/* reset.css V1.6  Start*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px '方正兰亭黑简体', Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html {
    font-size: $baseFontSize;
    background: #242b2f;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device) * $baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}
.wp1200 {
    width: 1200px;
    margin: 0 auto;
    @media (max-width: 1250px) {
        width: auto;
        margin: 0 px2rem(30px);
    }
}
.wp1360 {
    width: 1360px;
    margin: 0 auto;
    @media (max-width: 1366px) {
        width: 1250px;
    }
    @media (max-width: 1250px) {
        width: auto;
        margin: 0 px2rem(30px);
    }
}
.wp1600 {
    width: 1600px;
    margin: 0 auto;
    @media (max-width: 1600px) {
        width: 1440px;
    }
    @media (max-width: 1440px) {
        width: 1250px;
    }
    @media (max-width: 1250px) {
        width: auto;
        margin: 0 px2rem(30px);
    }
}
.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */
// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="prev">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="next" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式
.paged {
    text-align: center;
}
.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    margin: 0 2px 2px;
    padding: 0 15px;
    font-size: 13px;
    text-align: center;
    background: #fff;
    color: #595959;
    height: 36px;
    line-height: 36px;
}
.paged .current,
.paged a:hover {
    background: $blue;
    color: #fff;
}
.header {
    background-color: #fff;
    position: relative;
    z-index: 20;
    box-shadow: 0 30px 50px rgba(#95867a, .2);
    .wp1600 {
        display: flex;
        align-items: center;
        height: px2rem(120px);
        justify-content: space-between;
    }
    .header-wp {
        font-size: 0;
        display: flex;
        align-items: center;
    }
    .header-back {
        height: px2rem(120px);
        color: $blue;
        font-size: px2rem(44px);
        line-height: px2rem(120px);
        width: px2rem(70px);
        display: none;
        vertical-align: top;
        transform: rotate(180deg);
        text-align: right;
    }
    .logo {
        height: px2rem(67px);
        display: inline-block;
        vertical-align: top;
        a {
            display: block;
            height: 100%;
            img {
                display: block;
                height: 100%;
                width: auto;
            }
        }
    }
    @media (max-width: 1024px) {
        .wp1600 {
            height: px2rem(100px);
        }
        .logo {
            height: px2rem(67px);
        }
        .header-back {
            display: inline-block;
            height: px2rem(100px);
            line-height: px2rem(100px);
        }
    }
}
.header-search {
    position: relative;
    height: px2rem(120px);
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    margin-left: px2rem(70px);
    z-index: 20;
    &:hover {
        .header-search-box {
            opacity: 1;
            visibility: visible;
        }
    }
    &-icon {
        width: 25px;
        height: 25px;
        text-align: center;
        display: block;
        line-height: 25px;
        background: url(../images/icon1.png) center no-repeat;
    }
    &-box {
        position: absolute;
        right: 0;
        top: 75px;
        display: flex;
        width: 240px;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, .2);
        opacity: 0;
        visibility: hidden;
        background: #fff;
        button {
            display: block;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            //background-color: $baseBlue;
            border: none;
            color: #fff;
            font-size: 16px;
            appearance: none;
            transition: all .2s;
            cursor: pointer;
            background: url(../images/icon1.png) center no-repeat;
            background-size: 50% auto;
            &:hover {
                //background-color: lighten($baseBlue, 5%);
            }
        }
        .input {
            flex: 1;
            overflow: hidden;
            input {
                display: block;
                width: 100%;
                height: 40px;
                line-height: 40px;
                border: none;
                appearance: none;
                box-sizing: border-box;
                padding: 0 20px;
                font-size: 14px;
                color: #333;
            }
        }
    }
    @media (max-width: 1440px) {
        margin-left: px2rem(40px);
    }
    @media (max-width: 1200px) {
        display: none;
    }
}
.nav {
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        position: relative;
        > a {
            padding: 0 px2rem(40px);
            display: block;
            line-height: px2rem(120px);
            color: #333;
            font-size: 16px;
            text-align: center;
            position: relative;
            &:after {
                position: absolute;
                width: 1px;
                height: 8px;
                top: 50%;
                margin-top: -4px;
                right: 0;
                background: #c2c2c2;
                content: '';
            }
        }
        .sub {
            position: absolute;
            left: 0;
            top: px2rem(116px);
            background-color: #f9f9f9;
            box-shadow: inset 0 20px 20px rgba(#eee, .1);
            padding: 20px 0;
            display: none;
            width: 200px;
            border-top: 4px solid $blue;
            .item {
                > a {
                    display: block;
                    padding: 15px 10px 15px 30px;
                    font-size: 14px;
                    color: #333;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
        .child {
            .li {
                padding-left: 30px;
                a {
                    position: relative;
                    display: block;
                    padding-left: 20px;
                    font-size: 14px;
                    color: #333;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    &:before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 8px;
                        height: 8px;
                        border-radius: 100%;
                        border: 1px solid #a4a4a4;
                        margin-top: -5px;
                        content: '';
                    }
                    &:hover {
                        color: $blue;
                        &:before {
                            border-color: $blue;
                            background: $blue;
                        }
                    }
                }
            }
        }
        &:last-child {
            > a {
                &:after {
                    display: none;
                }
            }
        }
        &.on,
        &:hover {
            > a {
                color: $blue;
                &:before {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background: $blue;
                    display: block;
                    left: 0;
                    content: '';
                }
            }
        }
        @media (max-width: 1440px) {
            > a {
                padding: 0 px2rem(30px);
            }
            .sub {
                .item {
                    > a {
                        padding-left: 30px;
                    }
                }
            }
        }
    }
    @media (max-width: 1200px) {
        display: none;
    }
}
.mobile-menu-btn {
    height: px2rem(100px);
    line-height: px2rem(100px);
    font-size: px2rem(44px);
    color: #333;
    display: none;
    @media (max-width: 1200px) {
        display: block;
    }
}
.bottom-intro {
    background: #31383b;
    .wp1600 {
        font-size: 0;
        .item {
            display: inline-block;
            vertical-align: top;
            height: 114px;
            text-align: center;
            box-sizing: border-box;
            border-right: 1px solid rgba(255, 255, 255, .06);
            color: #fff;
            width: 20%;
            line-height: 114px;
            overflow: hidden;
            &:first-child {
                border-left: 1px solid rgba(255, 255, 255, .06);
            }
            .img {
                width: px2rem(50px);
                height: px2rem(50px);
                overflow: hidden;
                display: inline-block;
                vertical-align: middle;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .p {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                line-height: 30px;
            }
            .phone {
                display: inline-block;
                vertical-align: middle;
            }
            strong {
                display: block;
                font-family: '方正大黑简体';
                font-size: 20px;
                vertical-align: middle;
                line-height: 30px;
            }
            @media (max-width: 1440px) {
                .p {
                    font-size: 12px;
                }
                strong {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }
    @media (max-width: 1250px) {
        .wp1600 {
            .item {
                height: auto;
                padding: px2rem(30px) 0;
                line-height: 1;
                .phone {
                    display: block;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        .wp1600 {
            margin-right: 0;
            margin-left: 0;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 50%;
                border-bottom: 1px solid rgba(255, 255, 255, .06);
            }
        }
    }
}
.footer-con {
    padding: px2rem(50px) 0 px2rem(40px);
    background: #242b2f;
    .wp1600 {
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 1024px) {
        padding: px2rem(30px) 0;
        .wp1600 {
            display: block;
        }
    }
}
.copyright-con {
    .copyright {
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: px2rem(20px);
        a {
            color: #fff;
            &:hover {
                text-decoration: underline;
            }
        }
        .addition {
            display: none;
        }
    }
    .follow {
        .item {
            display: inline-block;
            vertical-align: top;
            width: 40px;
            height: 40px;
            margin-right: px2rem(20px);
            position: relative;
            z-index: 5;
            &:hover {
                > img {
                    opacity: .9;
                }
                .ewm {
                    display: block;
                }
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
            .ewm {
                position: absolute;
                width: 120px;
                height: 120px;
                padding: 5px;
                background: #fff;
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
                left: 50%;
                margin-left: -65px;
                top: -140px;
                border-radius: 4px;
                overflow: hidden;
                display: none;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        text-align: center;
        .follow {
            .item {
                margin: 0 px2rem(10px);
            }
        }
        .copyright {
            .addition {
                display: block;
            }
        }
    }
}
.fast-link-con {
    font-size: 0;
    .dl {
        display: inline-block;
        vertical-align: top;
        padding: 0 px2rem(40px);
        position: relative;
        @media (max-width: 1440px) {
            padding: 0 px2rem(30px);
        }
        &:after {
            position: absolute;
            width: 1px;
            height: 170px;
            top: 0;
            right: 0;
            content: '';
            background: rgba(#fff, .1);
            display: block;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
    .dt {
        margin-bottom: 20px;
        a {
            color: #fff;
            font-size: 16px;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .dd {
        margin-bottom: 15px;
        a {
            color: rgba(#fff, .2);
            font-size: 14px;
            &:hover {
                color: #fff;
            }
        }
    }
    @media (max-width: 1024px) {
        display: none;
    }
}
.bread {
    font-size: 14px;
    color: #444;
    line-height: 24px;
    a {
        color: #444;
        &:hover {
            text-decoration: underline;
        }
    }
    &.border {
        padding-bottom: px2rem(20px);
        border-bottom: 1px solid #e8e8e8;
    }
    &.pb-20 {
        padding-bottom: px2rem(20px);
    }
}
.detail-page {
    display: flex;
    &.mb40 {
        margin-bottom: px2rem(40px);
    }
    li {
        flex: 1;
        box-sizing: border-box;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 65px;
            background-color: $blue;
            transition: all .2s;
            color: #fff;
            box-sizing: border-box;
            padding: 0 3px;
            &:hover {
                background-color: #8ad5ea;
                i,
                span {
                    color: #fff;
                }
            }
        }
        i {
            font-size: 20px;
            margin: 0 10px;
            transition: all .2s;
        }
        span {
            transition: all .2s;
            font-size: 16px;
        }
        &:nth-child(1) {
            i {
                transform: rotate(180deg);
            }
        }
        &:nth-child(2) {
            border-right: 1px solid #8cd3e7;
            border-left: 1px solid #8cd3e7;
        }
    }
    @media (max-width: 750px) {
        li {
            a {
                height: 50px;
            }
            i {
                font-size: 16px;
                margin: 0 5px;
            }
            span {
                font-size: 12px;
            }
        }
    }
}
.nav-mobile {
    position: absolute;
    width: px2rem(530px);
    top: px2rem(196px);
    left: 0;
    bottom: 0;
    z-index: 103;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(-100%);
    transition: transform .4s ease;
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 9902;
        box-sizing: border-box;
        overflow: hidden;
        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .4);
            z-index: 102;
        }
    }
    &-top {
        width: 100%;
        height: px2rem(110px);
        line-height: px2rem(110px);
        box-sizing: border-box;
        padding: 0 px2rem(50px);
        background-color: $blue;
        font-size: px2rem(30px);
        color: #fff;
        z-index: 103;
        position: absolute;
        top: 0;
        left: 0;
    }
    &-search {
        position: absolute;
        left: 0;
        top: px2rem(110px);
        background-color: #f5f5f5;
        padding: px2rem(18px) px2rem(40px);
        display: flex;
        z-index: 104;
        width: 100%;
        box-sizing: border-box;
        input[type="text"] {
            flex: 1;
            height: px2rem(50px);
            line-height: px2rem(50px);
            appearance: none;
            border: none;
            font-size: px2rem(26px);
            background: none;
        }
        button {
            width: px2rem(80px);
            height: px2rem(50px);
            border: none;
            appearance: none;
            display: block;
            font-size: px2rem(40px);
            color: #555;
            background: url(../images/icon1.png) no-repeat center center;
            background-size: auto px2rem(30px);
        }
    }
    &-wp {
        position: relative;
    }
    &-title {
        position: relative;
        margin: 0 px2rem(50px);
        border-bottom: 1px solid #ededed;
        > a {
            position: relative;
            display: block;
            line-height: px2rem(84px);
            font-size: px2rem(28px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
            width: 100%;
        }
    }
    &-child-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(84px);
        font-size: px2rem(30px);
        font-weight: bold;
        z-index: 1;
        color: #848484;
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            position: absolute;
            display: block;
            width: px2rem(40px);
            height: 100%;
            top: 0;
            right: 0;
            color: #888;
            line-height: px2rem(84px);
            text-align: center;
            font-size: px2rem(30px);
            //transform: rotate(180deg);
            transition: transform .2s ease;
            &:before {
                content: '>';
            }
        }
        &.open {
            i {
                transform: rotate(90deg);
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub {
        padding: 0 px2rem(40px);
        display: none;
        li {
            border-bottom: 1px solid #ededed;
            padding-left: px2rem(30px);
            position: relative;
            //height: px2rem(84px);
            overflow: hidden;
            > a {
                display: block;
                line-height: px2rem(84px);
                font-size: px2rem(28px);
                height: px2rem(84px);
                color: #333;
                overflow: hidden;
            }
        }
    }
    .child {
        .li {
            border-top: 1px solid #ededed;
            a {
                display: block;
                position: relative;
                padding-left: px2rem(30px);
                font-size: px2rem(26px);
                color: #555;
                line-height: px2rem(80px);
                height: px2rem(80px);
                overflow: hidden;
                &:before {
                    position: absolute;
                    border: 1px solid #a4a4a4;
                    width: px2rem(8px);
                    height: px2rem(8px);
                    border-radius: 100%;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
.error-box {
    background-color: #fff;
    box-sizing: border-box;
    padding: px2rem(50px);
    text-align: center;
    img {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
    }
    .tips {
        margin: 20px auto;
        font-size: 18px;
        color: #666;
    }
    .btn {
        a {
            display: inline-block;
            vertical-align: top;
            width: 170px;
            height: 40px;
            margin: 0 2px;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            font-size: 16px;
            background: $blue;
            transition: all .2s;
            line-height: 40px;
            max-width: 45%;
            &:hover {
                background: lighten($blue, 5%);
            }
        }
    }
}
.common-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 130;
    background-color: rgba(0, 0, 0, .7);
    top: 0;
    left: 0;
    .dialog-box {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        width: 650px;
        height: 650px;
        backface-visibility: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .dialog-img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .dialog-close {
        position: absolute;
        width: 38px;
        height: 38px;
        border: 2px solid #fff;
        border-radius: 100%;
        text-align: center;
        line-height: 38px;
        color: #fff;
        font-size: 22px;
        top: 0;
        right: 0;
        z-index: 2;
        cursor: pointer;
    }
}
.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}