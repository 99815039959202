.brand-con {
    padding: px2rem(50px) 0 px2rem(80px);
}
.brand-article {
    line-height: 24px;
    color: #666;
    @include articleReset(14px, 24px);
    p {
        margin-bottom: 30px;
    }
    &-box {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .info {
            flex: 1;
            margin: 0 px2rem(60px);
            strong {
                color: $blue;
                font-size: 18px;
                font-family: '微软雅黑';
            }
        }
    }
    @media (max-width: 1024px) {
        &-box {
            display: block;
            .info {
                margin: px2rem(30px) 0 0;
            }
        }
    }
}