// 弹框
.honor-pop {
    display: none;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    .pop-con {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 920px;
        height: 800px;
        background-color: #fff;
        border-radius: 4px;
    }
    .box {
        padding: 70px 80px 0;
    }
    .box-img {
        position: relative;
        padding-top: (560/740) * 100%;
        background: #f5f5f3;
        backface-visibility: hidden;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }
    }
    .h4 {
        display: block;
        padding: 20px 0;
        margin-bottom: 15px;
        font-size: 24px;
        color: #444;
    }
    .p {
        font-size: 14px;
        line-height: 28px;
        color: #777;
    }
    .pop-close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: -60px;
        z-index: 1;
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: $blue;
        color: #fff;
        font-size: 30px;
        text-align: center;
        &:hover {
            opacity: .95;
        }
    }
}
@media screen and (max-height: 800px), (max-width: 950px) {
    .honor-pop {
        .pop-con {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
        .box {
            padding: 70px 10px 0;
        }
        .box-img {
            position: absolute;
            top: 70px;
            left: 10px;
            right: 10px;
            bottom: 150px;
            padding-top: 0;
        }
        .h4 {
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 50px;
            font-size: px2rem(38px);
        }
        .p {
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
        }
        .pop-close {
            top: 0;
            right: 0;
            width: 45px;
            height: 45px;
            line-height: 45px;
            font-size: 22px;
        }
    }
}
.honor-list {
    margin-right: -37px;
    margin-left: -37px;
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        padding: 0 37px;
        box-sizing: border-box;
        margin-bottom: px2rem(30px);
        .wp {
            cursor: pointer;
            &:hover {
                .t {
                    color: $blue;
                }
            }
        }
        .img {
            padding: 10px;
            background-color: #fff;
            margin-bottom: 20px;
            width: 100%;
            box-sizing: border-box;
            box-shadow: 0 20px 15px -10px rgba(0, 0, 0, .1);
            span {
                display: block;
                width: 100%;
                position: relative;
                padding-top: (212/285) * 100%;
                overflow: hidden;
                @include imgCommon(contain);
            }
        }
        .t {
            text-align: center;
            font-size: 16px;
            color: #444;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .arrow {
            margin: 0 auto;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            border: 2px solid $blue;
            text-align: center;
            line-height: 16px;
            font-size: 10px;
            color: $blue;
        }
    }
    @media (max-width: 1024px) {
        margin-right: -20px;
        margin-left: -20px;
        li {
            padding: 0 20px;
        }
    }
    @media (max-width: 900px) {
        margin-right: -10px;
        margin-left: -10px;
        li {
            padding: 0 10px;
        }
    }
    @media (max-width: 750px) {
        margin-right: -5px;
        margin-left: -5px;
        li {
            padding: 0 5px;
            width: 50%;
            .t {
                font-size: 14px;
            }
        }
    }
}