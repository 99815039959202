.notice-top {
    text-align: center;
    margin-bottom: px2rem(40px);
    form {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #333;
        margin-bottom: 5px;
    }
    .input {
        width: 170px;
        position: relative;
        border: 1px solid #d6dfe5;
        background-color: #fff;
        &:after {
            position: absolute;
            width: 24px;
            height: 14px;
            background-image: url(../images/date_img.jpg);
            background-repeat: no-repeat;
            top: 50%;
            margin-top: -7px;
            right: 0;
            content: '';
            z-index: 5;
        }
        input {
            width: 100%;
            text-align: center;
            font-size: 14px;
            line-height: 30px;
            background: none;
            border: none;
            appearance: none;
            box-sizing: border-box;
            padding: 0 25px 0 10px;
        }
    }
    button {
        background: none;
        cursor: pointer;
        appearance: none;
        font-size: 14px;
        line-height: 32px;
        padding: 0 10px;
        border: none;
        &:hover {
            color: $blue;
        }
    }
    .p {
        margin: 0 2px;
    }
    .tip {
        font-size: 14px;
        color: #666;
        a {
            color: #666;
            &:hover {
                color: $blue;
                text-decoration: underline;
            }
        }
    }
    @media (max-width: 450px) {
        .input {
            flex: 1;
        }
    }
}
.notice-table {
    margin-bottom: px2rem(30px);
    .top,
    .row {
        display: flex;
        .item {
            text-align: center;
            overflow: hidden;
            font-weight: bold;
            font-size: 14px;
            color: #333;
            line-height: 30px;
            box-sizing: border-box;
            &:nth-child(1) {
                flex: 8;
            }
            &:nth-child(2) {
                flex: 1;
            }
            &:nth-child(3) {
                flex: 3;
            }
            &:nth-child(4) {
                flex: 1;
            }
            &:nth-child(5) {
                flex: 2;
            }
        }
    }
    .row {
        background-color: #fff;
        &:nth-of-type(2n+1) {
            background-color: #f5f5f5;
        }
        .item {
            font-weight: normal;
            display: flex;
            min-height: 50px;
            align-items: center;
            line-height: 22px;
            justify-content: center;
            padding: 15px 0;
            .mobile-title {
                display: none;
            }
            &:nth-of-type(1) {
                justify-content: flex-start;
                text-align: left;
            }
            a {
                color: #333;
                margin: 0 10px;
                transition: color .2s;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
    @media (max-width: 800px) {
        .top {
            display: none;
        }
        .row {
            display: block;
            background-color: #fff !important;
            padding: 15px;
            margin-bottom: px2rem(30px);
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(#000, .1);
            .item {
                display: block;
                width: 100%;
                text-align: left;
                padding: 5px 0;
                min-height: 0;
                .mobile-title {
                    display: inline-block;
                    vertical-align: top;
                    color: $blue;
                }
                a {
                    margin: 0;
                }
            }
        }
    }
}