.res-article {
    padding: px2rem(30px) 0;
    .article {
        width: 1090px;
        max-width: 100%;
        font-size: 14px;
        line-height: 30px;
        color: #666;
        margin: 0 auto;
    }
}
.res-con {
    background-color: #fff;
    padding: px2rem(90px);
    position: relative;
    overflow: hidden;
    margin-bottom: px2rem(90px);
    @media (max-width: 900px) {
        padding-right: px2rem(30px);
        padding-left: px2rem(30px);
    }
}
.res-wp {
    position: relative;
    .dot {
        width: 28px;
        height: 28px;
        position: relative;
        z-index: 2;
        margin: 0 auto px2rem(40px);
        img {
            width: 100%;
            height: 100%;
        }
    }
    &:before {
        position: absolute;
        border-left: 1px dashed $blue;
        top: 0;
        bottom: 0;
        left: 50%;
        content: '';
    }
    .show-btn {
        width: 16px;
        height: 24px;
        position: absolute;
        left: 50%;
        margin-left: -8px;
        z-index: 5;
        cursor: pointer;
        bottom: -12px;
        animation-name: u2b;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        img {
            width: 100%;
            height: 100%;
        }
    }
    @media (max-width: 750px) {
        .dot {
            margin: 0 0 px2rem(40px) 0;
        }
        &:before {
            left: 14px;
        }
        .show-btn {
            left: 6px;
            margin-left: 0;
        }
    }
}
@keyframes u2b {
    0%,
    50%,
    100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(10px);
    }
    75% {
        transform: translateY(-10px);
    }
}
.res-list {
    position: relative;
    .item {
        position: relative;
        width: 50%;
        margin-top: px2rem(100px);
        &:nth-child(2n+1) {
            float: left;
            .box {
                padding-right: 80px;
                &:after {
                    right: -11px;
                }
            }
        }
        &:nth-child(2n) {
            float: right;
            .box {
                padding-left: 80px;
                &:after {
                    left: -11px;
                }
            }
        }
        &:first-child {
            margin-top: 0;
        }
        &:nth-child(n+7) {
            display: none;
        }
        @media (max-width: 900px) {
            &:nth-child(2n+1) {
                .box {
                    padding-right: 40px;
                }
            }
            &:nth-child(2n) {
                .box {
                    padding-left: 40px;
                }
            }
        }
    }
    .box {
        //width: 50%;
        box-sizing: border-box;
        position: relative;
        //margin-bottom: 100px;
        &:after {
            position: absolute;
            top: 6px;
            content: '';
            background-image: url(../images/icon14.jpg);
            background-position: center center;
            background-repeat: no-repeat;
            width: 22px;
            height: 22px;
        }
    }
    .t {
        font-family: '方正大黑简体';
        font-size: 30px;
        color: $blue;
        margin-bottom: 5px;
        line-height: 34px;
    }
    .p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
        color: #777;
    }
    .img {
        padding-bottom: 4px;
        background-color: #fff;
        box-shadow: 0 20px 10px -10px rgba(0, 0, 0, .1);
        img {
            display: block;
            width: 100%;
        }
    }
    @media (max-width: 750px) {
        .item {
            width: 100%;
            &:nth-child(2n+1) {
                float: none;
                .box {
                    padding-left: 30px;
                    padding-right: 0;
                    &:after {
                        right: auto;
                        left: 3px;
                    }
                }
            }
            &:nth-child(2n) {
                float: none;
                .box {
                    &:after {
                        left: 3px;
                    }
                }
            }
        }
        .t {
            font-size: 22px;
        }
    }
}