.course-con {
    padding: px2rem(40px) 0 px2rem(70px);
}
.course-list {
    margin-right: -16px;
    margin-left: -16px;
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        padding: 0 16px;
        margin-bottom: px2rem(44px);
        a {
            display: block;
            background-color: #fff;
            box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
            transition: all .4s;
            &:hover {
                transform: translateY(-10px);
                background-color: $blue;
                .img img {
                    transform: scale(1.05);
                }
                .t {
                    color: #fff;
                }
            }
        }
    }
    .img {
        width: 100%;
        position: relative;
        padding-top: (280/430) * 100%;
        overflow: hidden;
        @include imgCommon();
        &:before {
            position: absolute;
            font-family: 'iconfont';
            color: #fff;
            z-index: 2;
            font-size: px2rem(44px);
            left: px2rem(24px);
            bottom: px2rem(24px);
            content: '\e63a';
        }
    }
    .t {
        text-align: center;
        padding: 0 px2rem(20px);
        line-height: 84px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;
        color: #555;
    }
    @media (max-width: 750px) {
        margin-right: px2rem(-10px);
        margin-left: px2rem(-10px);
        li {
            width: 50%;
            padding: 0 px2rem(10px);
        }
        .t {
            line-height: 64px;
            font-size: 13px;
        }
    }
}