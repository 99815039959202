.solution-menu {
    position: relative;
    z-index: 40;
    width: 100%;
    display: flex;
    background-color: #fff;
    .item {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 124px;
        box-sizing: border-box;
        border-left: 1px solid #7f7f7f;
        cursor: pointer;
        &:first-child {
            border-left: none;
        }
        &.on {
            &:before {
                position: absolute;
                display: block;
                border-bottom: 10px solid #fff;
                border-right: 12px solid transparent;
                border-left: 12px solid transparent;
                content: '';
                top: -10px;
                left: 50%;
                margin-left: -12px;
            }
            .front {
                display: none;
            }
            .back {
                display: block;
            }
            .p {
                color: $blue;
            }
        }
        &:hover {
            .front {
                display: none;
            }
            .back {
                display: block;
            }
            .p {
                color: $blue;
            }
        }
    }
    .icon {
        width: 39px;
        height: 39px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .front {
        display: block;
    }
    .back {
        display: none;
    }
    .p {
        font-size: 20px;
        color: #333;
    }
    @media (max-width: 1440px) {
        .item {
            height: 100px;
        }
    }
    @media (max-width: 900px) {
        .item {
            flex-direction: column;
        }
        .p {
            font-size: 16px;
        }
    }
}
.solution-con {
    .box {
        display: none;
        //height: 810px;
        width: 100%;
        position: relative;
        overflow: hidden;
        &:first-child {
            display: block;
        }
        &:nth-child(1),
        &:nth-child(3) {
            cursor: url(../images/handle_ico_64.ico), pointer;
        }
        &:nth-child(2) {
            cursor: url(../images/handle_icon1.ico), pointer;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .bg {
            position: relative;
            z-index: 1;
            width: 100%;
            padding-top: (810/1920) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .switch-box {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 5;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
            .btn-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
            }
            .img-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
                img {
                    opacity: 0;
                    transform: translateY(-20px);
                    &.open {
                        transition: all .4s .4s;
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
        .switch-btn-light {
            position: absolute;
            width: (60/1920) * 100%;
            height: (60/810) * 100%;
            left: (1675/1920) * 100%;
            top: (442/810) * 100%;
            z-index: 40;
        }
        .switch-btn-sterilize {
            position: absolute;
            width: (60/1920) * 100%;
            height: (60/810) * 100%;
            left: (1757/1920) * 100%;
            top: (442/810) * 100%;
            z-index: 40;
        }
        .switch-btn-air {
            position: absolute;
            width: (60/1920) * 100%;
            height: (60/810) * 100%;
            left: (1675/1920) * 100%;
            top: (502/810) * 100%;
            z-index: 40;
        }
        .switch-btn-dry {
            position: absolute;
            width: (60/1920) * 100%;
            height: (60/810) * 100%;
            left: (1757/1920) * 100%;
            top: (502/810) * 100%;
            z-index: 40;
        }
        .lock-open {
            display: none;
        }
        .lock-btn {
            position: absolute;
            width: (78/1920) * 100%;
            height: (58/810) * 100%;
            left: (1124/1920) * 100%;
            top: (119/810) * 100%;
            z-index: 40;
        }
        .phone-box {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 15;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .mask-1,
        .mask-3 {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            transition: all .5s linear;
            background: rgba(#000, .5);
            &.hide {
                opacity: 0;
            }
        }
        .light {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            animation-name: l2d;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            opacity: 0;
        }
        .lock-light {
            display: none;
        }
        .phone {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 12;
        }
        .phone-back-btn {
            position: absolute;
            width: (236/1920) * 100%;
            height: (58/810) * 100%;
            left: (1515/1920) * 100%;
            top: (537/810) * 100%;
            z-index: 40;
        }
        .phone-out-btn {
            position: absolute;
            width: (236/1920) * 100%;
            height: (58/810) * 100%;
            left: (1515/1920) * 100%;
            top: (595/810) * 100%;
            z-index: 40;
        }
        .phone-up-btn {
            position: absolute;
            width: (236/1920) * 100%;
            height: (58/810) * 100%;
            left: (1515/1920) * 100%;
            top: (653/810) * 100%;
            z-index: 40;
        }
        .phone-en-btn {
            position: absolute;
            width: (236/1920) * 100%;
            height: (58/810) * 100%;
            left: (1515/1920) * 100%;
            top: (711/810) * 100%;
            z-index: 40;
        }
        .wind,
        .tv,
        .light-2,
        .light-3,
        .music {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 12;
            opacity: 0;
            transition: all .5s .5s linear;
        }
        .wind {
            transform: translateX(-20px);
            &.open {
                transform: translateX(0);
                opacity: 1;
            }
        }
        .music {
            transform: translateY(10px);
            &.open {
                transform: translateY(0);
                opacity: 1;
            }
        }
        .tv,
        .light-2,
        .light-3 {
            &.open {
                opacity: 1;
            }
        }
    }
    @media (max-width: 900px) {
        display: none;
    }
}
.solution-m-con {
    display: none;
    @media (max-width: 900px) {
        display: block;
    }
    .box {
        display: none;
        //height: 810px;
        width: 100%;
        position: relative;
        overflow: hidden;
        &:first-child {
            display: block;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .bg {
            position: relative;
            z-index: 1;
            width: 100%;
            padding-top: (677/750) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .switch-box {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 5;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
            .btn-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
            }
            .img-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
                img {
                    opacity: 0;
                    transform: translateY(-20px);
                    &.open {
                        transition: all .4s .4s;
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
        .switch-btn-light {
            position: absolute;
            width: (60/750) * 100%;
            height: (60/677) * 100%;
            left: (535/750) * 100%;
            top: (508/677) * 100%;
            z-index: 40;
        }
        .switch-btn-sterilize {
            position: absolute;
            width: (60/750) * 100%;
            height: (60/677) * 100%;
            left: (617/750) * 100%;
            top: (508/677) * 100%;
            z-index: 40;
        }
        .switch-btn-air {
            position: absolute;
            width: (60/750) * 100%;
            height: (60/677) * 100%;
            left: (535/750) * 100%;
            top: (568/677) * 100%;
            z-index: 40;
        }
        .switch-btn-dry {
            position: absolute;
            width: (60/750) * 100%;
            height: (60/677) * 100%;
            left: (617/750) * 100%;
            top: (568/677) * 100%;
            z-index: 40;
        }
        .lock-open {
            display: none;
        }
        .lock-btn {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 40;
        }
        .mask-1,
        .mask-3 {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            transition: all .5s linear;
            background: rgba(#000, .5);
            &.hide {
                opacity: 0;
            }
        }
        .light {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            animation-name: l2d;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            opacity: 0;
        }
        .phone {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 12;
        }
        .phone-box {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 15;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .phone-back-btn {
            position: absolute;
            width: (170/750) * 100%;
            height: (42/677) * 100%;
            left: (528/750) * 100%;
            top: (510/677) * 100%;
            z-index: 40;
        }
        .phone-out-btn {
            position: absolute;
            width: (170/750) * 100%;
            height: (42/677) * 100%;
            left: (528/750) * 100%;
            top: (552/677) * 100%;
            z-index: 40;
        }
        .phone-up-btn {
            position: absolute;
            width: (170/750) * 100%;
            height: (42/677) * 100%;
            left: (528/750) * 100%;
            top: (594/677) * 100%;
            z-index: 40;
        }
        .phone-en-btn {
            position: absolute;
            width: (170/750) * 100%;
            height: (42/677) * 100%;
            left: (528/750) * 100%;
            top: (636/677) * 100%;
            z-index: 40;
        }
        .wind,
        .tv,
        .light-2,
        .light-3,
        .music {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 12;
            opacity: 0;
            transition: all .5s .5s linear;
        }
        .wind {
            transform: translateX(-20px);
            &.open {
                transform: translateX(0);
                opacity: 1;
            }
        }
        .music {
            transform: translateY(10px);
            &.open {
                transform: translateY(0);
                opacity: 1;
            }
        }
        .tv,
        .light-2,
        .light-3 {
            &.open {
                opacity: 1;
            }
        }
    }
}
@keyframes l2d {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
.product-link {
    position: fixed;
    left: 0;
    top: 50%;
    z-index: 60;
    display: flex;
    width: 90px;
    height: 90px;
    background: $blue;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all .2s;
    margin-top: -45px;
    &:hover {
        background: lighten($blue, 5%);
    }
    i {
        font-size: 36px;
        margin-bottom: 5px;
    }
    span {
        font-size: 14px;
    }
    @media (max-width: 750px) {
        width: 50px;
        height: 50px;
        margin-top: -25px;
        i {
            font-size: 22px;
        }
        span {
            font-size: 10px;
        }
    }
}