.download-list {
    padding-bottom: px2rem(20px);
    li {
        margin-bottom: px2rem(20px);
        a {
            display: block;
            position: relative;
            padding-right: 60px;
            background-color: #fff;
            box-sizing: border-box;
            padding-left: px2rem(20px);
            font-size: 14px;
            color: #555;
            height: 60px;
            line-height: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: all .2s;
            &:after {
                font-family: 'iconfont';
                content: '\e60f';
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                text-align: center;
                line-height: 60px;
                color: $blue;
                font-size: 30px;
            }
            &:hover {
                color: $blue;
                box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
            }
        }
    }
}