.shop-banner {
    width: 100%;
    position: relative;
    padding-top: (580/1920) * 100%;
    overflow: hidden;
    @include imgCommon();
}
.shop-con {
    padding: px2rem(50px) 0 px2rem(90px);
    background: #f6f6f6;
}
.shop-menu {
    border-bottom: 1px solid #ababab;
    box-sizing: border-box;
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        width: auto;
        a {
            display: block;
            padding: 0 px2rem(40px) 30px;
            position: relative;
            font-size: 16px;
            color: #333;
            &:hover {
                color: $blue;
            }
            &:after {
                position: absolute;
                right: 0;
                width: 1px;
                height: 10px;
                background: #bfbfbf;
                top: 4px;
                content: '';
                display: block;
            }
        }
        &:last-child {
            a {
                &:after {
                    display: none;
                }
            }
        }
        &.on {
            a {
                color: $blue;
                &:before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background: $blue;
                    content: '';
                    display: block;
                }
            }
        }
    }
    @media (max-width: 750px) {
        .swiper-slide {
            a {
                padding-bottom: 20px;
                font-size: 14px;
            }
        }
    }
}
.shop-menu-sub {
    border-bottom: 1px solid #ddd;
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        width: auto;
        padding: 0 px2rem(40px);
        a {
            display: block;
            position: relative;
            height: 70px;
            line-height: 70px;
            font-size: 15px;
            color: #555;
            transition: all .2s;
            &:hover {
                color: $blue;
            }
            img {
                width: px2rem(39px);
                height: px2rem(39px);
            }
            .front {
                display: inline-block;
                vertical-align: middle;
            }
            .back {
                display: none;
            }
        }
        &.on {
            a {
                color: $blue;
                &:after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background: $blue;
                    content: '';
                    display: block;
                }
                .front {
                    display: none;
                }
                .back {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
    @media (max-width: 750px) {
        .swiper-slide {
            a {
                height: 50px;
                line-height: 50px;
                font-size: 12px;
                &:hover {
                    color: #555;
                }
            }
        }
    }
}
.shop-choose {
    display: flex;
    padding-top: px2rem(50px);
    margin-bottom: px2rem(36px);
    .row {
        position: relative;
        flex: 1;
        margin-right: 10px;
        input {
            display: block;
            width: 100%;
            border: 1px solid #eaeaea;
            box-sizing: border-box;
            padding: 0 20px;
            font-size: 14px;
            height: 50px;
            line-height: 50px;
            appearance: none;
            background: #fff;
            border-radius: 4px;
        }
        select {
            opacity: 0;
        }
        .shop-select {
            display: block;
            width: 100%;
            border: 1px solid #eaeaea;
            box-sizing: border-box;
            padding: 0 20px;
            font-size: 14px;
            height: 50px;
            line-height: 50px;
            background: #fff;
            border-radius: 4px;
            cursor: pointer;
            color: #7f7f7f;
            overflow: hidden;
            &:after {
                position: absolute;
                border-top: 4px solid #626262;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                display: block;
                top: 50%;
                right: 15px;
                content: '';
                margin-top: -4px;
            }
        }
    }
    button {
        display: block;
        width: 140px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        background: $blue;
        font-size: 14px;
        border: none;
        appearance: none;
        cursor: pointer;
        border-radius: 4px;
        transition: all .2s;
        &:hover {
            background: lighten($blue, 5%);
        }
    }
    @media (max-width: 750px) {
        display: block;
        .row {
            width: 100%;
            margin-right: 0;
            margin-bottom: 4px;
        }
        button {
            width: 100%;
        }
    }
}
.shop-map-con {
    position: relative;
}
.shop-map-box {
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    .map {
        width: 100%;
        height: px2rem(650px);
    }
}
.shop-list-con {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 140px;
    background-color: #fff;
    width: 340px;
    z-index: 4;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    @media (max-width: 1024px) {
        position: static;
        width: 100%;
    }
}
.shop-list-title {
    padding: 0 px2rem(40px);
    height: 62px;
    line-height: 62px;
    font-size: 16px;
    color: #3d3d3d;
    span {
        color: $blue;
    }
}
.shop-list-btn {
    display: flex;
    .btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        height: 70px;
        background: $blue;
        cursor: pointer;
        transition: all .2s;
        position: relative;
        outline: none;
        &:first-child {
            &:after {
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
                height: 100%;
                background: #fff;
                content: '';
                display: block;
            }
        }
        &:hover {
            background: lighten($blue, 5%);
        }
        i {
            font-size: 24px;
        }
        &.prev {
            i {
                transform: rotate(-90deg);
            }
        }
        &.next {
            i {
                transform: rotate(90deg);
            }
        }
    }
}
.shop-list-swiper {
    width: 100%;
    height: 558px;
    box-sizing: border-box;
    background-color: #fff;
    .swiper-slide {
        box-sizing: border-box;
        padding: 25px 35px 0;
        display: flex;
        cursor: pointer;
        border-bottom: 1px solid #efefef;
        &:first-child {
            border-top: 1px solid #efefef;
        }
        .num {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
            line-height: 30px;
            color: #fff;
            background: #c2c2c2;
            font-size: 18px;
            font-family: Arial;
            margin-right: 10px;
        }
        .info {
            flex: 1;
            overflow: hidden;
        }
        .t {
            font-size: 17px;
            color: #3d3d3d;
            margin-bottom: 8px;
        }
        .p {
            font-size: 14px;
            line-height: 24px;
            color: #878686;
            margin-bottom: 8px;
        }
        .phone {
            font-size: 14px;
            color: #747474;
        }
        &.on {
            background: #fafafa;
            .num {
                background: $blue;
            }
        }
    }
}