// 弹窗
.tips-popup {
    display: none;
    &.show {
        .tips-popup-mask {
            opacity: 1;
        }
        .tips-popup-box {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
    &-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9000;
        transition: all .3s;
    }
    &-box {
        position: fixed;
        top: 50%;
        left: 50%;
        backface-visibility: hidden;
        display: table;
        margin: auto;
        table-layout: fixed;
        padding: 18px 24px 8px;
        width: 320px;
        background-color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
        border-radius: 8px;
        z-index: 9001;
        opacity: 0;
        transform: translate(-50%, -50%) scale(.8);
        transition: all .3s;
        @media screen and (max-width: 414px) {
            max-width: calc(100% - .4rem);
            box-sizing: border-box;
        }
    }
    &-head {
        display: flex;
        padding-bottom: 10px;
        .title {
            flex: 1;
            min-width: 0;
            padding-top: 1px;
            line-height: 22px;
            font-size: 16px;
            color: #000;
        }
    }
    &-close {
        margin: 5px 0 0 10px;
        width: 14px;
        height: 14px;
        background: url(../images/tips_icon.png) top -130px left no-repeat;
        opacity: .7;
        z-index: 2;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            opacity: 1;
        }
    }
    &-content {
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
        color: #666;
    }
    &-footer {
        padding: 4px 0;
        text-align: right;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            padding: 8px 15px;
            line-height: 1;
            font-size: 12px;
            white-space: nowrap;
            border-radius: 3px;
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                opacity: .8;
            }
        }
        .cancle-btn {
            color: #666;
            background-color: #fff;
            border-color: #dcdfe6;
        }
        .submit-btn {
            color: #fff;
        }
    }
    .tips-icon {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        background-image: url(../images/tips_icon.png);
        background-repeat: no-repeat;
        &-success {
            background-position: 0 -104px;
        }
        &-question {
            background-position: 0 -78px;
        }
        &-info {
            background-position: 0 -52px;
        }
        &-error {
            background-position: 0 -26px;
        }
        &-alert {
            background-position: 0 0;
        }
    }
    &-icon {
        padding-left: 20px;
        padding-right: 20px;
        .tips-popup-content {
            padding-left: 34px;
        }
    }
}