.login-con {
    background-image: url(../images/login_bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: calc(100vh - .6rem);
    box-sizing: border-box;
    padding: px2rem(110px) px2rem(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        min-height: calc(100vh - .5rem);
    }
}
.login-box {
    display: block;
    background: #fff;
    border-radius: px2rem(20px);
    width: 510px;
    max-width: 100%;
    padding: px2rem(60px) px2rem(20px) px2rem(80px);
    .title {
        text-align: center;
        font-size: px2rem(42px);
        color: #333;
        margin-bottom: px2rem(20px);
    }
}
.login-form {
    display: block;
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
    .row {
        display: flex;
        border: 1px solid #e2e8ef;
        border-radius: 4px;
        background: #f6f6f6;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        padding: 0 px2rem(20px);
        margin-bottom: 10px;
    }
    .icon {
        font-size: 18px;
        color: #5f5f5f;
        margin-right: 6px;
    }
    .input {
        flex: 1;
        overflow: hidden;
        input {
            width: 100%;
            box-sizing: border-box;
            border: none;
            background: none;
            font-size: 14px;
            height: 50px;
            line-height: 50px;
            appearance: none;
        }
    }
    .captcha {
        margin-bottom: 10px;
    }
    button {
        display: block;
        width: 100%;
        font-size: 18px;
        height: 50px;
        line-height: 50px;
        border-radius: 4px;
        background: $blue;
        border: none;
        appearance: none;
        cursor: pointer;
        color: #fff;
        transition: all .2s;
        margin-bottom: 26px;
        &:hover {
            background: lighten($blue, 5%);
        }
    }
    .tip {
        text-align: center;
        font-size: 14px;
        color: #888;
    }
}